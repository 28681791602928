<template>
    <v-card class="ma-3" elevation="6" :style="{ backgroundColor: getBackgroundColor() }">
        <v-toolbar density="compact" color="primary darken-3 accent-4">
            <v-btn icon @click="add()" :disabled="props.disabled || (props.limit && array.length >= props.limit) || isValid() !== true">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <v-btn icon @click="remove()" :disabled="props.disabled || data.panel === -1">
                <v-icon>mdi-minus</v-icon>
            </v-btn>
            <v-btn icon @click="moveUp()" :disabled="props.disabled || data.panel === -1">
                <v-icon>mdi-arrow-up</v-icon>
            </v-btn>
            <v-btn icon @click="moveDown()" :disabled="props.disabled || data.panel === -1">
                <v-icon>mdi-arrow-down</v-icon>
            </v-btn>

            <v-spacer></v-spacer>
            <pre>{{ props.label }} {{ data.panel > -1 ? "#" + (1 + data.panel) : "" }}</pre>
            <v-spacer></v-spacer>
            <v-btn icon @click="close()" :disabled="props.disabled">
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        </v-toolbar>
        <div v-for="(e, i) in array" :class="{ select: data.panel === i }" :key="data.key + '-' + e.key + '-' + i">
            <div class="font-weight-bold pa-1 ma-1 pointer" :class="{ alert: e.valid !== true }" @click="select(i)">
                <pre>#{{ i + 1 }} {{ array[i].name }} {{ e.valid === true ? "" : " <- " + e.valid }} </pre>
            </div>
            <div class="pa-1">
                <vuetiform-component
                    v-if="data.panel === i"
                    :format="props.format"
                    :bond="getBond(i)"
                    :modelValue="array[i].value"
                    @update:modelValue="(...args) => atUpdate(i, ...args)"
                    :disabled="props.disabled"
                    :ref="getRef(i)"
                />
            </div>
        </div>
    </v-card>
</template>

<script setup>
// Dynamically add remove and sort elements
import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";

import { structuredClone, compare } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
function clone(p) {
    return structuredClone(toRaw(p));
}

// the identifier helps to find the array index in subsequent leaf components.
const props = defineProps(["bond", "modelValue", "disabled", "initialValues", "label", "panel", "identifier", "format", "namefield", "getElementName", "limit"]);
const emit = defineEmits(["update:modelValue"]);

function getBond(index) {
    if (!props.bond) return;
    const bond = props.bond || {};
    if (bond.stack) {
        // && props.identifier
        const stack = Array.from(bond.stack);
        stack.push({ [props.identifier || "VuetiformArray"]: index });
        return { ...bond, stack };
    }
    return bond;
}

const data = reactive({ panel: -1, key: 0 });
const array = reactive([]);

//if (props.panel !== undefined) data.panel = props.panel;

function getRef(index) {
    return (el) => {
        if (array[index]) array[index].ref = el;
        else console.log("getRef error", array, index);
    };
}

// -------------
async function atUpdate(index, value, nexus) {
    //if (compare(v, array[i].value)) return; // nothing to update
    array[index].value = value;
    array[index].valid = nexus.valid || true;
    array[index].name = await getName(index, value);
    updateHandler(null, { change: nexus.change });
}

// add capability to call a function at the top on certain events to refresh the rest of the content
async function edit(action) {
    updateHandler(null, { change: true });
}

function add() {
    const i = data.panel > -1 ? 1 + data.panel : array.length;
    let value;
    if (props.initialValues !== undefined) value = clone(props.initialValues);
    const o = { valid: null, ref: null, key: 0 };
    if (value !== undefined) o.value = value;
    array.splice(i, 0, o);
    // TODO if initialValues is undefined, value is undefined but the array has null as value. ??
    //Ł(toRaw(array), array, value, props.initialValues);
    data.panel = i;
    data.key++;
    edit("add");
}
function remove() {
    const i = data.panel > -1 ? data.panel : array.length - 1;
    array.splice(i, 1);
    data.panel = -1;
    data.key++;
    edit("remove");
}

function moveUp() {
    const i = data.panel;
    if (!arrayMove(array, i, i - 1)) return;
    data.panel = i - 1;
    data.key++;
    edit("moveUp");
}

function moveDown() {
    const i = data.panel;
    if (!arrayMove(array, i, i + 1)) return;
    data.panel = i + 1;
    data.key++;
    edit("moveDown");
}

async function close() {
    data.panel = -1;
    edit("close");
}
function select(i) {
    if (data.panel === i) {
        data.panel = -1;
        //return edit("deselect");
        return;
    }
    data.panel = i;
    //edit("select");
}

function isValid() {
  	for (const [i, e] of array.entries()) if (e.valid !== true) return props.label + " #" + (1+i) + " " + (e.valid || "invalid");
  	return true;
}
function updateHandler(value = null, nexus = { valid: true, change: false }) {
    const datum = array.map((e) => e.value);
    const valid = isValid();
    emit("update:modelValue", datum, { valid, change: nexus.change });
}

function arrayMove(arr, fromIndex, toIndex) {
    if (fromIndex < 0 || fromIndex >= arr.length || toIndex < 0 || toIndex >= arr.length) return false;
    arr.splice(toIndex, 0, arr.splice(fromIndex, 1)[0]);
    return true;
}

async function initValues(arrayValues = []) {
    array.length = 0;
    // for now we will assume it is valid by default
    for (const value of arrayValues) array.push({ value, valid: true, ref: null, key: 0, name: "..." });
    for (const [index, element] of array.entries()) element.name = await getName(index, element.value);
}

async function refreshValues(arrayValues = []) {
    //if (arrayValues.length !== array.length) Ł(arrayValues.length, array.length);
    //return initValues(arrayValues);

    for (const [i, x] of array.entries()) x.value = arrayValues[i];
}

async function refreshElements() {
    for (const [i, x] of array.entries()) {
        const r = array[i].ref;
        if (r) {
            if (r.refresh) r.refresh();
            //if (r.isValid) await (array[i].valid = r.isValid());
        }
    }
}

async function refresh() {
    //Ł("refresh array");
    refreshValues(props.modelValue);
    await refreshElements();
}

defineExpose({ refresh });

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

// todo , instead of ifs in the function, create the getName function by format?
let getName = (index, value) => {
    if (value === undefined || value === null) return "-";
    if (!isObject(value)) return value;
    if (props.namefield) return value[props.namefield];
    return Object.keys(value)
        .filter((key) => typeof value[key] === "string")
        .map((key) => value[key])
        .join(" ");
};

if (props.format?.getTextValue)
    if (typeof props.format.getTextValue === "function")
        getName = async (index, value) => {
            const v = await props.format.getTextValue(value);
            return v;
        };

onMounted(async () => {
    initValues(props.modelValue);
    await refreshElements();
    data.key++;
});

function getBackgroundColor() {
    const bond = props.bond || {};
    const depth = (bond.stack || []).length;
    return `rgba(150, 150, 150, ${0.05 + depth / 10})`;
}
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-array",
};
</script>

<style scoped>
.alert {
    color: red;
    font-weight: bold;
    background-color: rgba(255, 0, 0, 0.2) !important;
}
.select {
    background-color: rgba(150, 150, 250, 0.1) !important;
}
.hidden {
    display: none;
}
.pointer {
    cursor: pointer;
}
</style>
