<template>
    <v-container class="pt-10 flex-column">
        <vuetiform-card v-bind="c" :key="i" v-for="(c, key, i) in data.cards" />
    </v-container>
</template>

<script setup>
import { onMounted, onUnmounted, reactive, toRaw } from "vue";
//import VuetiformPoliform from "@/vuetiform/VuetiformPoliform.vue";
import VuetiformCard from "@/vuetiform-cards/VuetiformCard.vue";

import { useRouter, useRoute } from "vue-router";

const router = useRouter();

import { useSessionStore } from "@/store/sessionStore.mjs";
const sessionStore = useSessionStore();

const data = reactive({
    cards: {},
});

onMounted(async () => {
    await $connection();
    /*$socket.on("profile:getCards", (o) => {
        Object.assign(data.cards, o);
    });*/
    $socket.emit("profile:Profile:getCards", (o) => {
        Object.assign(data.cards, o);
    });
});
onUnmounted(async () => {
    //$socket.removeListener("profile:getCards");
});
</script>

<script>
export default {
    path: "/profile",
    name: "##&en Profile ##&hu Profil ##",
    icon: "mdi-account-box",
    root: "Home",
};
</script>
<style scoped>
.flex-column {
    display: flex;
    flex-direction: column;
    align-items: center; /* This centers the cards horizontally */
}
</style>
