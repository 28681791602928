<template>
    <div class="pa-16 bg-background-darken">
        <selector-component :structure="data.structure" @leftClickElement="click" />
    </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch } from "vue";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";

const emit = defineEmits(["leftClickElement"]);

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const data = reactive({
    structure: {},
});

const click = (element) => {
    router.push("/beacon/graph/" + element.macid);
};

onMounted(async () => {
    await $connection();

    $socket.on("beacons:Beacons:update", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("beacons:Beacons:update", {}, (o) => {
        Object.assign(data, o);
    });
});

onUnmounted(() => {
    $socket.removeListener("beacons:Beacons:update");
});
</script>
<script>
export default {
    path: "/beacons",
    name: "##&en Beacons ##&hu Jeladók ##",
    icon: "mdi-hvac",
    root: "Home",
};
</script>
