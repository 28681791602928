<template>
    <v-card-title class="pa-2">
        <h2>{{ document.name || document._id || "##&en New document ##&hu Új dokumentum ##" }}</h2>
    </v-card-title>

    <v-card-subtitle>
        <pre>
        {{ data.host || hostname }} {{ data.pattern }} {{ database }}.{{ collection }} {{ document._id || props.id }} {{ displayVersion(document) }}
        {{ displayDate(document._createdAt) }} {{ data.createdBy }} 
        {{ displayDate(document._updatedAt) }} {{ data.updatedBy }} 
      </pre
        >
    </v-card-subtitle>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["database", "collection", "id", "data", "document", "format"]);

const database = props.database;
const collection = props.collection;
const id = props.id;
const data = props.data;

const hostname = (ß.HOSTNAME || "localhost").split(".")[0];

const options = props.options || {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
    //weekday: "long",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
};

function displayDate(date) {
    if (!date) return "";
    date = new Date(date);

    const formatter = new Intl.DateTimeFormat("##&en en-US ##&hu hu-HU ##", options);
    return formatter.format(date);
}

function displayVersion(_document) {
    if (!_document) return "";
    if (!_document._version) return "";
    return "#" + parseInt(_document._version.slice(-6), 16) + " " + _document._version;
}

const openCollection = () => {
    router.push("/database/" + database + "/" + collection);
};
const openRemote = () => {
    if (!data.host) return;
    window.location.href = "https://" + data.host + "/document/" + database + "/" + collection + "/" + props.document._id;
};
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>
