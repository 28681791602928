import { defineStore } from "pinia";
import { reactive } from "vue";

import { structuredClone } from "../../helper-functions.mjs";

function clone(p) {
    return structuredClone(p);
}

export const useEventhandlerStore = defineStore("eventhandler-store", () => {
    const eventhandler = reactive({});
    const format = reactive({});

    function assign(o) {
        Object.assign(eventhandler, o.eventhandler);
        Object.assign(format, o.format);
    }

    function getContextkeys(stack = []) {
        const contextkeys = [];

        function processSequence(sequence, substack) {
            if (substack.length < 1) return;
            const current = substack.shift();
            const pointer = Object.values(current)[0];
            for (const [index, step] of sequence.entries()) {
                if (index === pointer) if (step.action) if (step.action.sequence) processSequence(step.action.sequence, clone(substack));
                if (index < pointer) if (step.contextkeys) contextkeys.push(step.contextkeys);
            }
        }

        processSequence(eventhandler.sequence, clone(stack));

        return contextkeys.flat();
    }

    return { assign, eventhandler, format, getContextkeys };
});
