<template>
    <v-tooltip location="bottom" open-delay="1000">
        <template v-slot:activator="{ props }">
            <v-chip class="ma-2 px-5" prepend-icon="mdi-thumb-up" variant="flat" color="primary" pill v-bind="props" @click="addLike()"
                >{{ (component_props.modelValue || []).length }} ##&en likes ##&hu kedvelés ##</v-chip
            >
        </template>
        <template v-slot:default="{ props }">
            <div style="white-space: pre-line">
                {{ getLikersList() || "--" }}
            </div>
        </template>
    </v-tooltip>
</template>

<script setup>
import { ref, onMounted, reactive, watch, nextTick, toRaw } from "vue";
import { structuredClone } from "../../helper-functions.mjs";

import { useSocketdataStore } from "@/store/socketdataStore.mjs";
const socket = useSocketdataStore();

function clone(p) {
    return structuredClone(toRaw(p));
}

const component_props = defineProps(["modelValue", "disabled", "index"]);
const emit = defineEmits("update:modelValue", "update:valid", "update");

const menu = ref(false);
const users = reactive({});
let addLike = () => {};

const getLikersList = () => {
    const arr = component_props.modelValue || [];
    if (arr.length < 1) return null;
    return (component_props.modelValue || []).map((id) => users[id]).join("\n");
};

onMounted(async () => {
    await $connection();

    await socket.initialization;
    const _id = socket.data._id;
    const ids = component_props.modelValue || [];

    $socket.emit("comments:common:getUserNames", { ids }, (o) => {
        Object.assign(users, o);
    });

    addLike = () => {
        let modelValue = clone(component_props.modelValue || []);
        if (modelValue.includes(_id)) modelValue = modelValue.filter((value) => value !== _id);
        else modelValue.push(_id);

        emit("update", modelValue);
        emit("update:modelValue", modelValue);
        emit("update:valid", true);
    };
});
</script>
<style scoped></style>
