<template>
    <v-card>
        <v-tabs bg-color="deep-purple-darken-4" center-active v-model="tab">
            <v-tab value="actions">Actions</v-tab>
            <v-tab value="settings">Settings</v-tab>
        </v-tabs>
        <v-card-text>
            <v-window v-model="tab">
                <v-window-item value="actions"><Actions /></v-window-item>
                <v-window-item value="settings"><Settings /></v-window-item>
            </v-window>
        </v-card-text>
    </v-card>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import Actions from "@/fox-components/Actions.vue";
import Settings from "@/fox-components/Settings.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const data = reactive({});
const tab = ref(null);

onMounted(async () => {
    await $connection();
});
</script>
<script>
export default {
    path: "/fox",
    name: "##&en Fox ##&hu Fox ##",
    icon: "mdi-tablet-cellphone",
    root: "Home",
};
</script>
<style scoped>
#selector-content {
    padding: 24px;
    padding-bottom: 100px;
    background-color: lightgray;
}
</style>
