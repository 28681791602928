<template>
    <v-card class="mx-auto pa-3 ma-3" width="800">
        <v-card-actions>
            <v-spacer />
            <v-btn variant="plain" icon="mdi-close" @click="emit('close')"></v-btn>
        </v-card-actions>
        <vuetiform-component :format="{ is: 'vuetiform-poliform', format: data.format }" :bond="bond" v-model="data.document" v-model:valid="data.valid" />
        <v-card-actions>
            <v-btn block variant="elevated" :disabled="data.valid !== true" :color="data.valid !== true ? 'gray' : 'primary'" @click="add()">
                ##&en Submit entry ##&hu Feljegyzés ##
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script setup>
import { reactive, toRaw } from "vue";
import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";

import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}

const emit = defineEmits(["close", "submit"]);

const props = defineProps({
    bond: { type: Object, required: true },
    document: { type: Object, required: false },
    format: { type: Object, required: true },
});

const data = reactive({
    document: clone(props.document || {}),
    valid: true,
});

const add = () => {
    emit("submit", data.document);
    data.document = clone(props.document || {});
};
</script>
