<template>
    <v-dialog v-model="dialog.open" width="auto">
        <v-card min-width="600">
            <v-card-actions>
                <span class="text-h5 pa-6">{{ data.count }} ##&en executions ##&hu végrehajtás ##</span>
                <v-spacer />
                <v-btn
                    size="large"
                    @click="
                        dialog.open = false;
                        data.panel = -1;
                    "
                    icon="mdi-close"
                />
            </v-card-actions>
            <v-card-text>
                <v-sheet v-for="(step, i) in data.history" :class="{ select: data.panel === i }">
                    <div class="font-weight-bold pa-1 ma-1 pointer" :class="{ alert: step.alert }" @click="select(i)">
                        <pre>{{ displayVersion(step.version) }} {{ displayDate(step.execid) }}</pre>
                    </div>
                    <div v-if="step.returns" class="pa-1" :class="{ hidden: data.panel !== i }">
                        <pre class="pa-1" v-for="(v, k, i) in step.returns">{{ k }} = {{ JSON.stringify(v, null, 4) }}</pre>
                    </div>
                </v-sheet>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn :color="data.panel > -1 ? 'primary' : 'gray'" variant="elevated" class="ma-1" min-width="200" @click="open()" :disabled="data.panel === -1">
                    <p style="text-transform: none"><v-icon>mdi-code-tags-check</v-icon> ##&en Review ##&hu Áttekintés ##</p>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick } from "vue";
import { isProxy, toRaw } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const props = defineProps(["options"]);

const dialog = reactive({
    open: false,
});

const data = reactive({
    panel: -1,
    history: [],
    count: 0,
});

function select(i) {
    if (data.panel === i) return (data.panel = -1);

    data.panel = i;
}

const options = props.options || {
    year: "numeric",
    month: "numeric",
    day: "2-digit",
    weekday: "long",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: false,
};

function displayVersion(vid) {
    if (!vid) return "";
    return "v" + parseInt(vid.slice(-6), 16);
}

function displayDate(id) {
    if (!id) return "";
    const date = new Date(parseInt(id.substring(0, 8), 16) * 1000);
    const formatter = new Intl.DateTimeFormat("##&en en-US ##&hu hu-HU ##", options);
    return formatter.format(date);
}

function open() {
    dialog.open = false;
    if (data.panel === -1) return;
    const version = data.history[data.panel].version;
    const execid = data.history[data.panel].execid;
    router.push({ params: { version, execid } });
    $socket.emit("eventhandlereditor:EventhandlerEditorDocument:executionfeedback", { id: route.params.id, version, execid });
    data.panel = -1;
}

onMounted(async () => {
    await $connection();

    $socket.on("eventhandlereditor:ExecutionHistory:dialog", (o) => {
        dialog.open = true;
        Object.assign(data, o);
    });
});
</script>
<style scoped>
.alert {
    color: red;
    font-weight: bold;
    background-color: rgba(255, 0, 0, 0.2) !important;
}
.select {
    background-color: rgba(var(--v-theme-primary), 0.3) !important;
}
.hidden {
    display: none;
}
.pointer {
    cursor: pointer;
}
</style>
