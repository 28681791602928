// vuetify theme
export default {
    dark: false,
    colors: {
        background: "#CCC",
      	shade: "#DDD",
        surface: "#EEE",
        primary: "#6200EE",
        secondary: "#03DAC6",
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
    },
};
