//import { createApp } from "vue";
//import { basicSetup } from "codemirror";
import VueCodemirror from "vue-codemirror";

//import { javascript } from "@codemirror/lang-javascript";

export default function ({ app }) {
    app.use(VueCodemirror, {
        // optional default global options
        autofocus: false,
        disabled: false,
        indentWithTab: true,
        tabSize: 2,
        placeholder: "",
        //extensions: [basicSetup],
        // ...
    });
}
