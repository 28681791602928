import * as d3 from "d3";

export default function graph(grs, { timefrom_ms = new Date().getTime(), timespan_ms = 60000, min = -2000, max = 2000, color = "#F00", data, name = "line", WIDTH = 2000, HEIGHT = 1000 }) {
    const selections = grs;

    // convert the array with timestamp and value, to pixel coordinates.
    function getX(t) {
        return ((t - timefrom_ms) / timespan_ms) * WIDTH;
    }

    function getY(v) {
        return ((HEIGHT) / (max - min)) * (max - v);
    }

    if (Array.isArray(data)) {
        const path_data = data.map((a) => [getX(a[0]), getY(a[1])]);
        if (path_data.length > 0) path_data.push([WIDTH, path_data[path_data.length - 1][1]]);
        selections.attr("d", d3.line().curve(d3.curveStepAfter)(path_data));
        return;
    }

    if (typeof data === "number") {
        const Y = getY(data);
        const line_data = [
            [0, Y],
            [WIDTH, Y],
        ];
        selections.attr("d", d3.line().curve(d3.curveStepAfter)(line_data));
        return;
    }
}
