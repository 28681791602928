<template>
    <div class="pa-16" v-if="data.format.database_collection?.items?.length > 0">
        <vuetifind-dialog icon="mdi-plus" :format="data.format" :modelValue="selection" @update:modelValue="add" label="##&en Add GUI ##&hu GUI Hozzáadása ##" />
        <selector-component :structure="data.structure" @leftClickElement="leftClickElement" @rightClickElement="rightClickElement" />
    </div>
    <v-container v-else>
        <v-alert
            v-if="data.format.database_collection"
            density="compact"
            type="warning"
            title="##&en No collection for GUIs ##&hu Nincs grafikus felület kollekció ##"
            text="##&en In order to create one, you  need to create a collection with the GUI pattern. ##&hu GUI-k használatához létre kell hozni egy adatbázis kollekciót, ami az GUI pattern alapján dolgozik ##"
        ></v-alert>
    </v-container>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";

const router = useRouter();

const selection = reactive({});

const data = reactive({
    structure: {},
    format: {},
});

const leftClickElement = ({ database, collection, _id }) => {
    router.push("/gui/" + database + "/" + collection + "/" + _id);
};
const rightClickElement = ({ database, collection, _id }) => {
    window.open("/gui/" + database + "/" + collection + "/" + _id, "_blank");
};

const add = (a) => {
    Object.assign(selection, a);
    $socket.emit("gui:GuiSelection:add", { selection }, ({ database, collection, _id }) => {
        if (_id) router.push("/gui/" + database + "/" + collection + "/" + _id);
    });
};

onMounted(async () => {
    await $connection();

    $socket.on("update-gui", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("gui:GuiSelection:update", {}, (o) => {
        Object.assign(data, o);
    });
});

onUnmounted(async () => {
    $socket.removeListener("update-gui");
});
</script>
<script>
export default {
    path: "/gui",
    name: "##&en GUI ##&hu GUI ##",
    icon: "mdi-television-guide", //"mdi-monitor-dashboard",
    root: "Home",
};
</script>
