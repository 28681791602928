<template>
    <div :class="props.classes || 'text-h7 pa-2 ml-2'">
        <pre>{{ props.label }}</pre>
        <v-container>
            <v-btn-toggle density="compact" v-model="data.sel" class="ma-2">
                <v-btn v-for="(v, k, i) in interpreters" @click="interpreterSelectionHandler(k)">
                    <v-icon>{{ v.icon }}</v-icon>
                </v-btn>
            </v-btn-toggle>
            <pre> {{ interpreters[data.interpreter].name }}</pre>
            <v-chip v-for="(chip, i) in getContextItems()" @click="drop(chip)" class="ma-1"> {{ chip._type }} {{ chip.value }} </v-chip>
        </v-container>
        <vuetiform-component
            :format="{ is: interpreters[data.interpreter].is, pretext: interpreters[data.interpreter].pretext, lang: interpreters[data.interpreter].lang }"
            :modelValue="interpreters[data.interpreter].code"
            :key="data.key"
            :context="getContext()"
            @update:modelValue="updateHandler"
        />
        <v-select
            v-if="(returntypes || []).length > 1"
            label="##&en Return type ##&hu Visszatérési típus ##"
            :disabled="(interpreters[data.interpreter].returntypes || []).length < 2"
            :items="interpreters[data.interpreter].returntypes"
            :modelValue="interpreters[data.interpreter].type"
            :key="data.key"
            @update:modelValue="changeHandler"
            prepend-icon="mdi-desktop-classic"
        />
    </div>
</template>
<script setup>
import { isProxy, toRaw } from "vue";
import { ref, reactive, nextTick, onMounted } from "vue";
import { structuredClone as clone } from "../../helper-functions.mjs";
import { getCurrentInstance } from "vue";

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";

import { useEventhandlerStore } from "@/store/eventhandlerStore.mjs";
const store = useEventhandlerStore();

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

const props = defineProps(["interpreters", "returntypes", "bond", "label", "classes", "modelValue", "items", "icon", "mandatory", "filters"]);
const emit = defineEmits(["update:modelValue"]);

const data = reactive({ sel: 0, interpreter: Object.keys(props.interpreters)[0], key: 0 });

const interpreters = reactive({});

for (const interpreter in props.interpreters) {
    const a = props.interpreters[interpreter].returntypes || [];
    const b = props.returntypes || [];
    if (a.some((i) => b.includes(i))) interpreters[interpreter] = { code: "", valid: true, type: b[0], ...clone(props.interpreters[interpreter]) };
}

function decode(modelValue = {}) {
    let interpreter = Object.keys(interpreters)[0];
    const o = { ...modelValue };
    if (o.interpreter !== undefined) interpreter = o.interpreter;
    if (!interpreters[interpreter]) return console.error("No interpreter for " + interpreter);
    if (o.code === undefined) o.code = "";
    if (o.returntype === undefined) o.returntype = interpreters[interpreter].returntypes[0];
    Object.assign(interpreters[interpreter], o);
  	  	Ł(clone(interpreters));

}
  
decode(props.modelValue);

function encode() {
    if (interpreters[data.interpreter].lang === "mathjs") return { interpreter: data.interpreter, code: interpreters[data.interpreter].code, returntype: "Number" };
    return { interpreter: data.interpreter, code: interpreters[data.interpreter].code, returntype: interpreters[data.interpreter].type };
}

/*
async function refresh() {}
defineExpose({ refresh });
*/

function drop(chip) {
    const o = interpreters[data.interpreter];
  	o.code += chip.value;
    data.key++;
    changeHandler();
}

function getContextItems() {
    const unfiltered = store.getContextkeys(props.bond.stack);
    if (!props.filters) return unfiltered;
    const filters = props.filters.split("|");
    const filtered = unfiltered.filter((e) => filters.includes(e._type));
    return filtered;
}
function getContext() {
    const context = {};
    const items = getContextItems();
    for (const o of items) context[o.name] = null;
    return context;
}

function isValid() {
    return interpreters[data.interpreter].valid;
}

function interpreterSelectionHandler(k) {
    data.interpreter = k;
    data.key++;
}

function changeHandler(type) {
  	interpreters[data.interpreter].type = type;
    updateEmitter();
}

function updateHandler(value, nexus = {}) {
    interpreters[data.interpreter].code = value;
    interpreters[data.interpreter].valid = nexus.valid || true;
    updateEmitter();
}

function updateEmitter() {
    emit("update:modelValue", encode(), { valid: isValid(), change: false });
}

//onMounted();
</script>

<script>
export default {
    inheritAttrs: false,
    name: "interpreter-evaluation",
};
</script>
