import validators from "../../validators.mjs";
export default function (format) {
    const rules = [];
    if (format.validators) {
        let list = format.validators || [];
        if (typeof format.validators === "string") list = format.validators.split("|");
        //if (format.mandatory === true) list.push("validateMandatoryField");
        for (const i of [...new Set(list)])
            rules.push((input) => {
                const r = validators[i](input);
                if (r === true) return true;
                if (r === false) return "Invalid"; // TODO add better reasoning
                return r;
            });
    }
    if (format.regex)
        rules.push((str) => {
            const re = new RegExp(format.regex, "i");
            if (re.test(str) === false) return "##&en Regex check failed. ##&hu regex ellenőrzés hibajelzés ##";
            return true;
        });
  	return rules;
}
