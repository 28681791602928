<template>
    <div id="login">
        <v-container>
            <v-row justify="space-around">
                <v-card width="500">
                  	<slot name="header"></slot>
					<slot name="content"></slot>
                    <div v-for="c in login_components">
                        <component :is="c" :autologin="props.autologin" @complete="emit('complete')" />
                    </div>
                    <slot name="footer"></slot>
                </v-card>
            </v-row>
        </v-container>
    </div>
</template>

<script setup>
import LanguageButton from "@/multilanguage-components/LanguageButton.vue";

const props = defineProps(["autologin"]);
const emit = defineEmits(["complete"]);

import components from "../login-components.mjs";
const login_components = Object.keys(components).map((e) => components[e]);

import { useSessionStore } from "@/store/sessionStore.mjs";
const sessionStore = useSessionStore();

</script>

<style scoped>
#login {
    overflow-y: scroll;
    height: 100vh;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        width: 0px;
        height: 0px;
    }
}
</style>
