<template>
    <v-card v-resize="onResize">
        <v-container fluid> </v-container>
        <v-card class="overflow-y-auto" :height="resizedHeight">
            <selector-component :structure="data" @leftClickElement="leftClickElement" />
        </v-card>
    </v-card>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const data = reactive({
    //users: [],
});

const resizedHeight = ref(0);
const onResize = () => {
    resizedHeight.value = window.innerHeight - 210;
};

const leftClickElement = (e) => {
    if (e.route) router.push(e.route);
};

const update = () => {
    $socket.emit("update-onlineusers-view", (o) => {
        //Object.assign(data, o);
    });
};

onMounted(async () => {
    await $connection();
    $socket.removeListener("update-onlineusers-view");

    $socket.on("update-onlineusers-view", (o) => {
        Object.assign(data, o);
    });
    update();
});
</script>
<script>
export default {
    path: "/online-users",
    name: "##&en Online Users ##&hu Online felhasználók ##",
    icon: "mdi-account-multiple",
    root: "Home",
};
</script>
<style scoped>
.pointer {
    cursor: pointer;
}
.error {
    color: red;
}
.notice {
    color: orangered;
}
.message {
    color: green;
}
</style>
