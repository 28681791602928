<template>
    <v-container fluid class="pa-10" v-if="!data.beacon"> Beacon is not registered </v-container>
    <v-container fluid class="pa-10" v-else>
        <v-row no-gutters>
            <v-col cols="1" style="max-width: 50px; padding-top: 20px">
                <v-card width="30" :height="HEIGHT + 50" style="padding-top: 10px">
                    <v-range-slider
                        :style="'padding-top: 10px; height: ' + SLIDERHEIGHT + 'px !important'"
                        v-model="selection.y"
                        min="0"
                        :max="HEIGHT"
                        direction="vertical"
                        class="large-slider"
                    />
                </v-card>
            </v-col>
            <v-col cols="11">
                <v-card :width="WIDTH">
                    <v-toolbar density="compact">
                        <pre> {{ graphInfo }}</pre>
                    </v-toolbar>
                    <beacon-graph-component :macid="route.params.macid" :data="data" :selection="selection" :width="WIDTH" :height="HEIGHT"/>
                </v-card>
                <v-card :width="WIDTH">
                    <v-range-slider v-model="selection.x" min="0" :max="WIDTH" />
                    <v-toolbar density="compact">
                        <v-btn icon @click="openBeaconDocument()"> <v-icon v-if="data.beacon">mdi-hvac</v-icon><v-icon v-if="!data.beacon">mdi-plus</v-icon> </v-btn>
                        {{ route.params.macid }}
                        <v-spacer></v-spacer>
                        <v-btn icon @click="tooglePlay()">
                            <v-icon v-if="data.play">mdi-stop</v-icon>
                            <v-icon v-if="!data.play">mdi-play</v-icon>
                        </v-btn>
                        <v-btn icon @click="modifyTimespan(1)" :disabled="data.timespan_ms > 500000">
                            <v-icon>mdi-magnify-minus</v-icon>
                        </v-btn>
                        <v-btn icon @click="modifyTimespan(-1)" :disabled="data.timespan_ms < 10000">
                            <v-icon>mdi-magnify-plus</v-icon>
                        </v-btn>
                        <v-btn icon @click="data.time -= data.timespan_ms / 10" :disabled="data.play">
                            <v-icon>mdi-chevron-left</v-icon>
                        </v-btn>
                        <v-btn icon @click="data.time += data.timespan_ms / 10" :disabled="data.play">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="openInfo()">
                            <v-icon>mdi-information-outline</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-card-actions>
                        <v-container v-if="data.beacon">
                            <v-row class="pa-4">
                                <pre>{{ beaconInfo }}</pre>
                            </v-row>
                            <v-row :key="data.beacon.model">
                                <v-col cols="6">
                                    <v-select
                                        dense
                                        label="##&en Sensors ##&hu Szenzorok ##"
                                        :items="getSensingItems()"
                                        v-model="data.sensing"
                                        @update:modelValue="updateSensing"
                                    ></v-select>
                                </v-col>
                                <v-col cols="6">
                                    <v-select dense label="##&en Graphs ##&hu Gráfok ##" multiple :items="getGraphItems(data.sensing)" v-model="data.graphs"></v-select>
                                </v-col>
                            </v-row>
                            <v-row class="pa-4">
                                <v-col cols="6">
                                    <v-select :items="ALGO" v-model="data.algo" dense label="##&en Algorithm ##&hu Alogoritmus ##" />
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        v-model="data.logic"
                                        dense
                                        :disabled="data.graphs.length <= 1"
                                        label="##&en Combination logic ##&hu Kombinációs logika ##"
                                        :items="[
                                            { title: '##&en Or ##&hu Vagy ##', value: true },
                                            { title: '##&en And ##&hu És ##', value: false },
                                        ]"
                                    ></v-select>
                                </v-col>
                            </v-row>
                            <v-row class="pa-4">
                                <v-btn @click="addCalibration()" variant="elevated" color="primary" class="ma-1" min-width="200">
                                    <p style="text-transform: none"><v-icon class="pr-3"> mdi-file-document-plus-outline </v-icon>##&en + Calibration ##&hu + Kalibráció ##</p>
                                </v-btn>
                            </v-row>
                            <v-row class="pa-4" v-if="data.beacon" v-for="(e, i) in data.beacon.calibrations || []">
                                <div style="text-transform: none">
                                    <pre>{{ e.name }}</pre>
                                </div>
                            </v-row>
                            <!--v-row class="pa-4" v-if="data.beacon">
                                <div style="text-transform: none">
                                    <pre>{{ data.beacon }}</pre>
                                </div>
                            </v-row -->
                        </v-container>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick } from "vue";

import BeaconGraphComponent from "@/beacon-components/D3Graph.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
  
//Ł(window.innerWidth);

let WIDTH = 1000;
let HEIGHT = 500;
let SLIDERHEIGHT = 920;

if (window.innerWidth > 1900) {
	WIDTH = 1600;
	HEIGHT = 800;
	SLIDERHEIGHT = 1820;
}

if (window.innerWidth > 2850) {
	WIDTH = 2200;
	HEIGHT = 1100;
	SLIDERHEIGHT = 2720;
}  
  
if (window.innerWidth > 3800) {
	WIDTH = 3200;
	HEIGHT = 1600;
	SLIDERHEIGHT = 4220;
}

const ALGO = [
    { title: "##&en All Values within selection ##&hu Minden érték a kijelölésben van ##", value: "all-values" },
    { title: "##&en No Values within selection ##&hu Nincs érték a kijelölésben ##", value: "no-values" },
    { title: "##&en Has a value in selection ##&hu Van érték a kijelölésben ##", value: "has-values" },
    { title: "##&en Has a value outside of the selection ##&hu Van érték a kijelölésen kívül ##", value: "out-values" },
    { title: "##&en Within tolerance of the selection size ##&hu Az értékek a kijelölés méretének megfelelő toleranciatartományban vannak ##", value: "within-tolerance" },
    { title: "##&en Out of the tolerance of the selection size ##&hu Az értékek a kijelölés méretének megfelelő toleranciatartományon kívül vannak ##", value: "out-of-tolerance" },
];

const data = reactive({
    sensing: null,
    graphs: null,
    play: false,
    time: new Date().getTime(),
    timespan_ms: 60000,
    offset_ms: 60000,
    min: null,
    max: null,
    beacon: null, // {}
    algo: ALGO[2].value,
    logic: true,
    index: null,
});

const selection = reactive({
    x: [0, WIDTH],
    y: [0, HEIGHT],
});

const beaconInfo = computed(() => {
    let str = "";
    if (!data.beacon) return str;
    if (data.beacon.name) str += data.beacon.name;
    if (!data.beacon.beacon_model) return str;
    if (!data.beacon.beacon_model.name) return str;
    str += " " + data.beacon.beacon_model.name;
    return str;
});

const graphInfo = computed(() => {
    let str = "";
    str += "##&en Range ##&hu Tartomány ##: " + data.min + " .. " + data.max;
    str += " | ##&en Timespan ##&hu Átfogás ##: " + Math.floor(data.timespan_ms / 1000) + " ##&en seconds ##&hu másodperc ##";
    if (!data.beacon) return str;
    if (!data.beacon.beacon_model) return str;
    const sensors = data.beacon.beacon_model.sensors;
    const unit = sensors[data.sensing].metrics.unit;
    str += " | ##&en Selection ##&hu Kijelölés ##:";
    str +=
        " ##&en Offset ##&hu Eltolódás ## " + Math.floor(((1 - Math.min(selection.x[0], selection.x[1]) / WIDTH) * data.timespan_ms) / -1000) + " ##&en seconds ##&hu másodperc ##";
    str += " ##&en Timespan ##&hu Időablak ## " + Math.floor(((Math.abs(selection.x[0] - selection.x[1]) / WIDTH) * data.timespan_ms) / 1000) + " ##&en seconds ##&hu másodperc ##";
    str += " ##&en Values ##&hu Értékek ## min: " + Math.floor(data.min + (Math.min(selection.y[0], selection.y[1]) / HEIGHT) * (data.max - data.min)) + " " + unit;
    str += " max: " + Math.floor(data.min + (Math.max(selection.y[0], selection.y[1]) / HEIGHT) * (data.max - data.min)) + " " + unit;
    return str;
});

function setSensingDefault() {
    if (data.sensing) return;
    if (!data.beacon) return;
    if (!data.beacon.beacon_model) return;
    if (!data.beacon.beacon_model.sensors) return;
    const sensors = data.beacon.beacon_model.sensors;
    data.sensing = Object.keys(sensors)[0];
    data.graphs = Object.keys(sensors[data.sensing].measurements);
    data.min = sensors[data.sensing].metrics.min;
    data.max = sensors[data.sensing].metrics.max;
}

function getSensingItems() {
    if (!data.beacon) return [];
    if (!data.beacon.beacon_model) return [];
    if (!data.beacon.beacon_model.sensors) return [];
    const sensors = data.beacon.beacon_model.sensors;
    return Object.keys(sensors).map((value) => ({ title: sensors[value].title, value }));
}

const sensings = [];

function getGraphItems(sensing) {
    if (!sensing) return [];
    if (!data.beacon) return [];
    if (!data.beacon.beacon_model) return [];
    if (!data.beacon.beacon_model.sensors) return [];
    const sensors = data.beacon.beacon_model.sensors;
    return Object.keys(sensors[sensing].measurements);
}

function updateSensing(v) {
    data.graphs = [];
    if (!data.sensing) return;
    if (!data.beacon) return;
    if (!data.beacon.beacon_model) return;
    if (!data.beacon.beacon_model.sensors) return;
    const sensors = data.beacon.beacon_model.sensors;
    data.graphs = Object.keys(sensors[data.sensing].measurements);

    data.min = sensors[data.sensing].metrics.min;
    data.max = sensors[data.sensing].metrics.max;
}

function tooglePlay() {
    data.play = !data.play;
    if (!data.play) data.time = new Date().getTime();
}

function modifyTimespan(f) {
    if (f > 0) if (data.timespan_ms < 500000) data.timespan_ms = data.timespan_ms * 1.25;
    if (f < 0) if (data.timespan_ms > 10000) data.timespan_ms = data.timespan_ms / 1.25;
}

function openInfo() {
    router.push("/beacon/info/" + route.params.macid);
}

function addCalibration() {
    const sensing = data.sensing;
    const logic = data.logic;
    const keys = data.graphs;
    const algo = data.algo;
    const max = Math.floor(data.min + (Math.max(selection.y[0], selection.y[1]) / HEIGHT) * (data.max - data.min));
    const min = Math.floor(data.min + (Math.min(selection.y[0], selection.y[1]) / HEIGHT) * (data.max - data.min));
    const offset = Math.floor(((1 - Math.min(selection.x[0], selection.x[1]) / WIDTH) * data.timespan_ms) / -1000);
    const timespan = Math.floor(((Math.abs(selection.x[0] - selection.x[1]) / WIDTH) * data.timespan_ms) / 1000);
    $socket.emit("beacons:BeaconGraph:addcalibration", { sensing, keys, algo, logic, max, min, offset, timespan, macid: route.params.macid }, (o) => {
        if (o.uri) return router.push(uri);
        Object.assign(data, o);
    });
}

function openBeaconDocument() {
    $socket.emit("beacons:Beacons:open", { macid: route.params.macid }, ({ uri }) => {
        if (uri) router.push(uri);
    });
}

onMounted(async () => {

    await $connection();
    $socket.emit("beacons:BeaconGraph:timecheck", { time: new Date().getTime() });
    $socket.emit("beacons:BeaconGraph:init", { macid: route.params.macid }, async (o) => {
        if (o.uri) router.push(o.uri);
        Object.assign(data, o);
        setSensingDefault();
    });
});
</script>
<script>
export default {
    path: "/beacon/graph/:macid",
    name: "beacon-graph",
    icon: "mdi-hvac",
    sort: 100,
    root: "Home",
};
</script>
<style scoped></style>
