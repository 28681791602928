<template>
    <v-container>
        <v-row justify="space-around">
            <v-card width="500">
                <v-card-title>
                    <div class="text-h5 pa-1 mb-4">Vuetiform Poliform Test</div>
                </v-card-title>

                <v-card-text>
                    <vuetiform-poliform :format="format" v-model="data.form" v-model:valid="data.valid" />
                </v-card-text>

                <v-card-subtitle> </v-card-subtitle>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="">button</v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
    <v-container>
        <v-row justify="space-around">
            <v-card width="500"> data {{ data }} </v-card>
        </v-row>
    </v-container>
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";

import VuetiformPoliform from "@/vuetiform-components/VuetiformPoliform.vue";
const data = reactive({
    form: {
        email: "LaKingD250.hu",
    },
});

const format = {
    headline: {
        is: "vuetiform-text",
        label: "Login",
        classes: "text-h5 pa-1 mb-4",
    },
    name: {
        is: "v-text-field",
        label: "Name",
    },
    selector: {
        is: "v-select",
        label: "Select",
        default: "Email",
        items: ["Email", "Phone"],
        formats: {
            Email: {
                email: {
                    is: "v-text-field",
                    label: "E-mail",
                    validators: "validateEmail",
                },
                password: {
                    is: "v-text-field",
                    label: "Email Password",
                    "append-icon": "mdi-eye",
                    disabled: true,
                },
            },
            Phone: {
                phone: {
                    is: "v-text-field",
                    label: "Phone",
                    //validators: "validateEmail",
                },
                password: {
                    is: "v-text-field",
                    label: "Phone Password",
                    "append-icon": "mdi-eye",
                    disabled: true,
                },
            },
        },
    },
};
</script>

<script>
//console.log("@DEV", ß.DEBUG);

export default {
    inheritAttrs: false,
    path: "/hello-vuetiform-poliform",
    name: "hello-vuetiform-poliform",
    icon: "mdi-medical-bag",
    sort: 9000,
};
</script>
<!--

        formats: {
            Email: [
                {
                    key: "email",
                    is: "v-text-field",
                    label: "E-mail",
                    validators: "validateEmail",
                },
                {
                    key: "password",
                    is: "v-text-field",
                    label: "Email Password",
                    "append-icon": "mdi-eye",
                    disabled: true,
                },
            ],
            Phone: [
                {
                    key: "phone",
                    is: "v-text-field",
                    label: "Phone",
                    //validators: "validateEmail",
                },
                {
                    key: "password",
                    is: "v-text-field",
                    label: "Phone Password",
                    "append-icon": "mdi-eye",
                    disabled: true,
                },
            ],
        }

-->
