<template>
    <v-container v-if="data.description" class="fill-height" fluid>
        <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="6">
                <v-card class="pa-2 mt-2 message-card" outlined tile>
                    <v-row no-gutters>
                        <v-col cols="auto" class="icon-column">
                            <v-icon>mdi-chat-question</v-icon>
                        </v-col>
                        <v-col>
                            <div v-html="marked(data.description)" class="message-content"></div>
                        </v-col>
                    </v-row>
                </v-card>
                <!-- Messages -->
                <v-card v-for="e in data.list" class="pa-2 mt-2 message-card" outlined tile>
                    <v-row no-gutters>
                        <v-col cols="auto" class="icon-column">
                            <v-icon v-if="e.role === 'user'">mdi-account</v-icon>
                          	<v-icon v-if="e.role === 'assistant'">mdi-cloud-outline</v-icon>
                          	<div>{{i}}</div>
                        </v-col>
                        <!--pre>{{ JSON.stringify(e.content) }}</pre-->
                        <v-col v-for="(t, i) in e.content">
                            <div v-if="t.type === 'text'" v-html="marked(t.text.value)" class="message-content"></div>
                        </v-col>
                    </v-row>
                </v-card>

                <!-- Input Area -->
                <v-card v-if="!data.isLoading" class="pa-2 mt-2" outlined tile>
                    <div class="input-area mt-4">
                        <v-textarea
                            label="##&en Ask the assistant ##&hu Fordulj az asszisztenshez ##"
                            autofocus
                            rows="1"
                            no-resize
                            auto-grow
                            prepend-icon="mdi-account"
                            v-model="data.userQuery"
                        >
                        </v-textarea>
                        <v-btn icon class="send-btn" @click="sendQuery">
                            <v-icon>mdi-send</v-icon>
                        </v-btn>
                    </div>
                </v-card>

                <!-- Loading -->
                <v-card class="pa-2 mt-2" v-if="data.isLoading" outlined tile>
                    <v-progress-circular indeterminate color="primary"> </v-progress-circular>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
    <v-container v-else class="fill-height" fluid>
        <v-row justify="center" align="center">
            <v-col cols="12" sm="8" md="6">
                <!-- Loading -->
                <v-card class="pa-2 mt-2" outlined tile>
                    <v-progress-circular indeterminate color="primary"></v-progress-circular>
                    ... ##&en please wait a moment. ##&hu Egy pillanat türelmet kérnék. ##
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { marked } from "marked";

import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const data = reactive({
    description: null,
    userQuery: "",
    isLoading: false,
    list: [],
    response: null,
});

const sendQuery = () => {
    const content = data.userQuery;
    /*data.messages.push({
        user: "##&en You ##&en Én ##",
        role: "user",
        content,
    });*/
    data.isLoading = true;
    $socket.emit("assistant:Assistant:message", { database: route.params.database, id: route.params.id, thread_id: route.params.thread_id, content }, (o) => {
        Ł(o);
        Object.assign(data, o);
        data.isLoading = false;
        //if (o.response) data.messages.push(o.response);
    });

    console.log(data.userQuery);
    data.userQuery = "";
};

onMounted(async () => {
    await $connection();

    $socket.emit(
        "assistant:Assistant:update",
        {
            database: route.params.database,
            id: route.params.id,
            thread_id: route.params.thread_id,
        },
        (o) => {
            Ł(o, route.params.thread_id);
            Object.assign(data, o);
            if (o.thread_id !== route.params.thread_id)
                router.push({
                    params: {
                        thread_id: o.thread_id,
                    },
                });
        },
    );
});
</script>

<script>
export default {
    path: "/assistant/:database/:id:/:thread_id?",
    name: "openai-assistant",
    icon: "mdi-chat-question",
    sort: 100,
    root: "Home",
};
</script>

<style scoped>
.message-card {
    display: flex;
    align-items: start;
}

.message-content {
    margin-left: 20px; /* Adjust the margin as needed */
}

.input-area {
    display: flex;
    align-items: flex-end;
}

.send-btn {
    margin-left: 8px; /* Space between textarea and send button */
    margin-bottom: 24px; /* Align with the bottom of the textarea */
}

/* Optional: Adjust textarea width if needed */
.v-textarea {
    flex-grow: 1;
}
</style>
