<template>
    <!--v-infinite-scroll :items="Object.keys(walldata)" :onLoad="load">
        <template v-for="(id, index) in sortdata" :key="id">
            <v-container>
                <wall-card :identifier="props.identifier" :id="id" :document="walldata[id]" :readonly="props.readonly" :key="id"></wall-card>
            </v-container>
        </template>
        <template v-slot:empty="{ props }"> -- </template>
    </v-infinite-scroll-->
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch, toRaw } from "vue";
//import { VInfiniteScroll } from "vuetify/labs/VInfiniteScroll";

import WallCard from "@/wall-components/WallCard.vue";

const props = defineProps(["module", "identifier", "readonly"]);

const walldata = reactive({});
const sortdata = computed(() => {
    return Object.keys(walldata).sort().reverse();
});

const load = async ({ done }) => {
    const lastone = Object.keys(walldata).sort().reverse().pop();
    $socket.emit(props.module + ":WallStream:load", { identifier: props.identifier, lastone }, (w) => {
        Object.assign(walldata, w);
        if (Object.keys(w).length === 0) done("empty");
        else done("ok");
    });
};

onMounted(async () => {
    await $connection();

    $socket.on(props.module + ":WallStream:update", (o) => {
        Object.assign(walldata, o);
    });
});

onUnmounted(async () => {
    $socket.removeListener(props.module + ":WallStream:update");
});
</script>

<style scoped></style>
