<template>
    <div v-for="(v, k, i) in data.shortcuts">
        <v-btn :icon="v.icon" @click="router.push(v.route)" :title="v.route + ' ' + v.name" class="tooltip-button"/>
    </div>
</template>

<script setup>
// TODO customize toolbar
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const data = reactive({
    shortcuts: [],
});

onMounted(async () => {
    $socket.emit("navigation:Shortcuts:init", (o) => {
        Object.assign(data, o);
    });

    $socket.on("navigation:update-shortcuts", (o) => {
        Object.assign(data, o);
    });
});
</script>
