<template>
    <div
        v-if="data.condition"
        :class="{
            valid: data.valid === true,
            invalid: data.valid !== true,
            readonly: data.format.readonly === true,
            preinvalid: data.format.valid !== undefined && data.format.valid !== true,
        }"
    >
        <component
            :is="data.format.is"
            :bond="props.bond"
            v-bind="data.format"
            :modelValue="data.modelValue"
            @update:modelValue="updateHandler"
            @input="inputHandler"
            @click:clear="clearHandler"
            @change="changeHandler"
            class="pa-3"
            :ref="getRef('component')"
            :rules="data.rules"
        >
        </component>
    </div>
</template>

<script setup>
// this components goal is to merge vuetify and vuetiform components, and add a layer of common extra functionality
// keep it minimalistic

const Đ = (msg, ...args) => {
    Ł("---", msg, data.format.is, data.format.label, JSON.stringify(...args));
};

import { ref, reactive, watch, nextTick, toRaw, onMounted } from "vue";
import { compare } from "../../helper-functions.mjs";
import getFormatValidationRules from "@/vuetiform/getFormatValidationRules.mjs";

const props = defineProps(["bond", "format", "modelValue", "disabled", "readonly"]);
const emit = defineEmits(["update:modelValue", "update:valid", "change"]);
const data = reactive({ format: {}, modelValue: props.modelValue, condition: true, valid: true });

function setDefaultValue() {
    if (data.format.default === undefined) return false;
    if (data.modelValue !== undefined) return false;
    data.modelValue = data.format.default;
    return true;
}

function context() {
    return { Document: () => toRaw(props.bond?.document || {}), Datafield: () => toRaw(props.format || {}), __from_VuetiformComponent_context: true };
}

import functionalFormat from "@/vuetiform/functionalFormat.mjs";
async function updateFunctionalFormat() {
    if (!props.format) {
        console.error("%cError: Vuetiform component must have a format prop!!", "color: red;");
        console.log(toRaw(props));
    }

    const document = toRaw(props.bond?.document || {});

    data.format = await functionalFormat.call(context(), { format: toRaw(props.format || {}), value: props.modelValue });
    data.format.rules = getFormatValidationRules(props.format);

    if (props.format.condition)
        if (props.format.condition.hashtags) {
            const tags = props.format.condition.hashtags;
            if ((document._hashtags || []).some((tag) => tags.includes(tag))) data.condition = true;
            else {
                data.condition = false;
                if (data.modelValue !== undefined) return updateEmitter(undefined, { change: true });
            }
        }
}

async function updateFunctionalValues() {
    if (data.format.getComponentValue) {
        if (typeof data.format.getComponentValue !== "function") return console.error("%cError in format.getComponentValue, not a function ", "color: red;");
        data.modelValue = await data.format.getComponentValue.call(context(), data.modelValue);
        Ł("updateFunctionalValues:getComponentValue", data.format.getComponentValue);
        return updateHandler();
    }

    if (setDefaultValue()) return updateEmitter();
}

// :ref="getRef('component')"
const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    Đ("refresh0", { "data.modelValue": data.modelValue, "props.modelValue": props.modelValue });

    await updateFunctionalFormat();
    //if (compare(toRaw(data.modelValue), toRaw(props.modelValue)) && data.valid === isValid()) return Đ("skip-refresh", );
    //Đ("refresh1", { "data.modelValue": data.modelValue, "props.modelValue": props.modelValue });
    data.modelValue = props.modelValue;
    await updateFunctionalValues();
    //Đ("refresh2", { "data.modelValue": data.modelValue, "props.modelValue": props.modelValue });
    await refreshComponent();
    //Đ("refresh3", { "data.modelValue": data.modelValue, "props.modelValue": props.modelValue });
    const valid = isValid();
    Đ("refresh valid:", valid);
    if (valid !== data.valid) {  
      await nextTick();
      updateEmitter(data.modelValue, { valid, change: false });
    }
    return "OK refresh @ VuetiformComponent " + data.format.is;
}

async function refreshComponent() {
    await nextTick();
    if (!refs.component) return;
    if (!refs.component.refresh) return;
    Đ("refreshComponent");
    refs.component.refresh();
}

function isValid() {
    const format = data.format;
    if (!format) return true;
    if (data.valid !== true) return data.valid;
    if (format.condition === false) return true;
    if (format.readonly === true) return true;
    if (format.mandatory === true) {
        if (data.condition === false) return true;
        if (data.modelValue === undefined || data.modelValue === null) return (format.label || "") + " ##&en Mandatory field! ##&hu Kötelező mező! ##";
        if (data.modelValue === "") return (format.label || "") + " ##&en Can not be blank ##&hu Nem lehet üres ##";

        if (format.items) {
            const items = format.items.map((e) => e.value);
            if (items.includes(data.modelValue)) return true;
            return (format.label || "") + " ##&en Invalid selection ##&hu Érvénytelen választott mező ##";
        }
    }
    const rules = getFormatValidationRules(format);
    for (const rule of rules) {
        // check if i is a valid validator? check if modelValue is a string?
        const r = rule(data.modelValue);
        if (r === false) "???";
        if (r === false) return "##&en Invalid. ##&hu Érvénytelen bejegyzés ##";
        if (r !== true) return (format.label || "") + " - " + r;
    }

    return true;
}

defineExpose({ refresh, isValid });

function typeConverter(datum) {
    if (typeof datum === "string") {
        let d = datum.replace(/,/g, ".").replace(/\s/g, "");
        if (d.endsWith(".")) d += "0";
        const n = Number(d);
        if (!isNaN(n)) return n;
    }
    return datum;
}

function inputHandler(event) {
    //Ł(event);
}

function updateHandler(datum, nexus = {}) {
    if (nexus.valid === undefined) nexus.valid = true;
    if (nexus.change === undefined) nexus.change = false;

    if (data.format._type === "Number") datum = typeConverter(toRaw(datum));

    data.valid = nexus.valid;
    data.modelValue = datum;
    Đ("updateHandler", { datum, nexus });
    updateEmitter(datum, nexus);
}

function updateEmitter(datum = data.modelValue, nexus = {}) {
    if (nexus.valid === undefined) nexus.valid = true;
    if (nexus.change === undefined) nexus.change = false;

    //if (compare(toRaw(data.modelValue), datum)) if (nexus.valid === data.valid && nexus.change === false) return Đ("no-change");
	const valid = isValid();
    nexus.valid = valid;
  	data.valid = valid;
    if (["v-select", "v-autocomplete", "v-combobox", "database-document"].includes(data.format.is)) nexus.change = true;

    Đ("updateEmitter", datum, nexus);
    emit("update:modelValue", datum, nexus);
}

function changeHandler(datum) {
    Đ("changeHandler", { datum, "data.modelValue": data.modelValue });
    updateEmitter(data.modelValue, { change: true });
}

function clearHandler() {
    data.modelValue = undefined;
    setDefaultValue();
    Đ("clearHandler", { "data.modelValue": data.modelValue });
    updateHandler(data.modelValue, { change: true });
}

onMounted(async () => {
    await updateFunctionalFormat();
    await updateFunctionalValues();
    data.valid = isValid();
    if (data.valid !== true) updateEmitter();
});
</script>

<script>
import * as vuetify from "vuetify/components";
import vuetiform from "../../vuetiform-components.mjs";

export default {
    inheritAttrs: false,
    components: { ...vuetify, ...vuetiform },
    name: "vuetiform-component",
};
</script>
<style scoped>
.valid {
    border-left: 2px solid rgba(0, 0, 0, 0);
}
.invalid {
    background-color: rgba(128, 0, 0, 0.02);
    border-left: 3px solid red;
}
.readonly {
    background-color: rgba(0, 0, 256, 0.2);
    border-left: 2px solid blue;
}
.preinvalid {
    background-color: rgba(128, 0, 0, 0.1);
    border-left: 2px solid orange;
}
</style>
