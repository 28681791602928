1
<template>
    <v-card-title class="pa-4">
        <h2>{{ data.name || collection }}</h2>
    </v-card-title>
    <v-card-subtitle>
        <pre>
        {{ data.host || hostname }} {{ data.pattern }} {{ database }}.{{ collection }} 
        {{ countInfo() }} ##&en Documents ##&hu Dokumentum ## {{ data.filter }}
        {{ data.readonly ? "##&en Read-only ##&hu Csak-olvasható ##" : "" }} {{ data.sortable && !data.readonly ? "##&en Sortable ##&hu Rendezhető ##" : "" }}
        </pre>
    </v-card-subtitle>
</template>

<script setup>
import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const props = defineProps(["database", "collection", "query", "data"]);

const database = props.database;
const collection = props.collection;
const query = props.query;

const hostname = (ß.HOSTNAME || "localhost").split(".")[0];

// inherit from parent component, CollectionView
const data = props.data;

const countInfo = () => {
    let a = [data.total];
    if (data.count !== data.total) a.unshift(data.count);
    if (data.documents.length !== data.count) a.unshift(data.documents.length);
    return a.join(" / ");
};

const openCollection = () => {
    router.push("/database/" + database + "/" + collection);
};

const openRemote = () => {
    if (!data.host) return;
    window.location.href = "https://" + data.host + "/database/" + database + "/" + collection;
};
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
</style>
