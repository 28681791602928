export default function compare(i, j) {

  	if (Number.isNaN(i) && Number.isNaN(j)) return true;
  
    const type = Object.prototype.toString.call(i);

    if (type !== Object.prototype.toString.call(j)) return false;

    if (type === "[object Date]") return i.getTime() === j.getTime();

    if (type === "[object Array]") {
        return i.length === j.length && i.every((elem, index) => compare(elem, j[index]));
    }

    if (type === "[object Object]") {
        const keysI = Object.keys(i);
        const keysJ = Object.keys(j);

        return keysI.length === keysJ.length && keysI.every((key) => Object.prototype.hasOwnProperty.call(j, key) && compare(i[key], j[key]));
    }

  	// functions?
  	// maps, sets, regexp?
  
    return i === j;
}
