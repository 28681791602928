<template>
    <v-container class="pa-4">
        <v-btn @click="router.push('/beacons')" variant="elevated" color="primary" class="ma-1" min-width="200">
            <p style="text-transform: none"><v-icon class="pr-3 pa-2"> mdi-hvac </v-icon>##&en Beacons ##&hu Jeladók ##</p>
        </v-btn>
        <v-btn @click="openBeaconDocument()" variant="elevated" color="primary" class="ma-1" min-width="200">
            <p style="text-transform: none"><v-icon class="pr-3 pa-2"> mdi-database </v-icon>##&en Open ##&hu Open ##</p>
        </v-btn>
        <v-btn @click="graph()" variant="elevated" color="primary" class="ma-1" min-width="200">
            <p style="text-transform: none"><v-icon class="pr-3 pa-2"> mdi-chart-timeline-variant </v-icon>##&en Graph ##&hu Gráf ##</p>
        </v-btn>
    </v-container>
    <v-container class="pa-2">
        <v-card max-width="2000" class="ma-3 pa-2">
            <v-table fixed-header density="compact">
                <thead>
                    <tr>
                        <th class="text-left">Key</th>
                        <th class="text-left">Value</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(item, index) in sortedData" :key="index">
                        <td :style="{ width: '300px' }">{{ item.key }}</td>
                        <td>{{ item.value }}</td>
                    </tr>
                </tbody>
            </v-table>
        </v-card>
    </v-container>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch } from "vue";

import { io } from "socket.io-client";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const latestdata = reactive({});
const data = reactive({});
const macid = route.params.macid;

const graph = () => {
    router.push("/beacon/graph/" + macid);
};

function openBeaconDocument() {
    $socket.emit("beacons:Beacons:open", { macid: route.params.macid }, ({ uri }) => {
        if (uri) router.push(uri);
    });
}

const sortedData = computed(() => {
    // Convert object to array of key-value pairs and sort by key
    return Object.entries(latestdata || [])
        .map(([key, value]) => ({ key, value }))
        .sort((a, b) => a.key.localeCompare(b.key));
});

let sensor_socket;
onMounted(async () => {
    await $connection();

    const options = ß.SOCKETIO_OPTIONS || {};

    const connect = "https://" + ß.HOSTNAME + ":" + ß.SERVER_PORT + "/beacon-info";
    console.log("[socket.io] " + connect, options);

    options.withCredentials = true;
    options.forceNew = true;

    sensor_socket = io(connect, options);
    sensor_socket.connect();

    while (!sensor_socket.connected) await new Promise((resolve) => setTimeout(resolve, 100));

    sensor_socket.emit("init-info", macid, (o) => {
        Object.assign(latestdata, o.latestdata);
    });

    sensor_socket.on("addto-info", (e) => {
        Object.assign(latestdata, e);
    });
});

onUnmounted(() => {
    sensor_socket.disconnect();
});
</script>
<script>
export default {
    path: "/beacon/info/:macid",
    name: "beacon-info",
    icon: "mdi-hvac",
    sort: 100,
    root: "Home",
};
</script>
<style scoped></style>
