<template>
    <vuetiform-component :format="{ is: 'vuetiform-poliform', format: data.format }" v-model="data.query" v-model:valid="data.valid" :key="data.key" />
</template>

<script setup>
// based on poliform
// added the query store, since all sub-components in logical operations have the same format definitions
// so the format is not passed as props, it comes from the store

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
function clone(p) {
    return structuredClone(toRaw(p));
}
import { useQueryStore } from "@/store/queryStore.mjs";
const store = useQueryStore();

// identifier and index are needed for flexiform array index identification.
const props = defineProps(["format", "modelValue", "disabled", "identifier", "index"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive(clone(props.modelValue || {}));

// validators
const valid = reactive({});
const isValid = () => {
    //for (const i in valid) if (valid[i] !== true) return i + ": " + valid[i];
    return true;
};

const updateHandler = async (from) => {
    await nextTick();
    const datum = clone(data);
    emit("update", datum);
    emit("update:modelValue", datum);
    //emit("update:valid", isValid());
};
//updateHandler();
emit("update:valid", isValid());

onMounted(async () => {
    // maybe
    //emit("update:valid", isValid());
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-queriesform",
};
</script>
