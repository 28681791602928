<template>
    <v-container>
        <v-row justify="center">
            <v-dialog v-model="data.dialog" @update:modelValue="close()" @update="close()" @click:outside="close()">
                <LoginCard @complete="completeHandler()" :autologin="true">
                    <template v-slot:header>
                        <v-card-actions>
                            <v-btn @click="close()" class="ml-auto">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                    </template>
                </LoginCard>
            </v-dialog>
        </v-row>
    </v-container>
</template>

<script setup>
//width="500"
import LoginCard from "@/LoginCard.vue";

const emit = defineEmits(["update:modelValue", "update"]);
const props = defineProps(["modelValue"]);

import { ref, reactive, onMounted, watch } from "vue";

import { useSessionStore } from "@/store/sessionStore.mjs";
const sessionStore = useSessionStore();

const data = reactive({ dialog: props.modelValue || false });
watch(
    () => props.modelValue,
    (newValue) => {
        data.dialog = newValue;
    },
);

const completeHandler = async () => {
    await sessionStore.fetchSession();
    data.dialog = false;
    emit("update:modelValue", false);
    emit("update", false);
};
const close = async () => {
    data.dialog = false;
    emit("update:modelValue", false);
    emit("update", false);
};
</script>
