<template>
    <v-list-item prepend-icon="mdi-palette" @click="theme_selection()" :title="theme.global.name.value"></v-list-item>
</template>

<script setup>
import { onMounted, onActivated } from "vue";

import { useTheme } from "vuetify";
const theme = useTheme();

let theme_selection = () => {};

onMounted(async () => {
    await $connection();

    $socket.emit("theme:ThemeSelector:init", {}, (o) => {
        theme.global.name.value = o.theme;
    });

    theme_selection = () => {
        $socket.emit("theme:ThemeSelector:next", { theme: theme.global.name.value }, (o) => {
            theme.global.name.value = o.theme;
        });
    };
});
</script>
