<template>
    <v-list-item v-for="v in data.pages" :prepend-icon="v.icon" :title="v.name" @click="open(v)"></v-list-item>
</template>

<script setup>
import { onMounted, reactive } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

function open(v) {
  if (v.target) return window.open(v.link, v.target)
  router.push(v.link)
}
  
const data = reactive({
    pages: [],
});

const init = () => {
    $socket.emit("navigation:Navigation:init", {}, (o) => {
        Object.assign(data, o);
    });
};

onMounted(async () => {
    await $connection();
    init();
});
</script>
