// vuetify theme
export default {
    dark: true,
    colors: {
        background: "#00254b",
      	shade: "#001a4f",
        surface: "#001245",
        primary: "#00458b", // Cobalt blue
        secondary: "#00356b", // Lighter, secondary cobalt blue
        error: "#B00020",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FB8C00",
    },
};
