<template>
    <LoginCard>
        <template v-slot:header>
            <v-card-actions>
                <v-btn v-if="router.currentRoute.value.path !== '/'" icon="mdi-home" @click="router.push('/')" />
                <v-spacer />
                <LanguageButton />
            </v-card-actions>
        </template>
        <template v-slot:content>
            <v-img height="300px" src="@/login-assets/login.jpg"> </v-img>
        </template>
    </LoginCard>
</template>

<script setup>
import LoginCard from "@/LoginCard.vue";
import LanguageButton from "@/multilanguage-components/LanguageButton.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
</script>

<script>
export default {
    path: "/login",
    name: "login",
};
</script>
