<template>
    <div :class="props.classes || 'text-h7 pa-2 ml-2'">
        <v-select
            :modelValue="data.value"
            :label="props.label"
            :items="getItems()"
            @update:modelValue="updateHandler"
            :prepend-icon="props.icon || 'mdi-currency-usd'"
            append-icon="mdi-arrow-down-drop-circle"
        />
    </div>
</template>
<script setup>
import { isProxy, onMounted, toRaw } from "vue";
import { ref, reactive, nextTick } from "vue";
import { structuredClone as clone } from "../../helper-functions.mjs";
import { getCurrentInstance } from "vue";

import { useEventhandlerStore } from "@/store/eventhandlerStore.mjs";
const store = useEventhandlerStore();

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

const props = defineProps(["bond", "label", "classes", "modelValue", "items", "icon", "filters"]);
const emit = defineEmits(["update:modelValue"]);

const data = reactive({ value: props.modelValue });

function getItems() {
    const unfiltered = store.getContextkeys(props.bond.stack);
    if (!props.filters) return unfiltered;
    const filters = props.filters.split("|");
    const filtered = unfiltered.filter((e) => filters.includes(e._type));
    return filtered;
}

function isValid() {
    const arr = getItems();
    if (arr.length < 1) return "##&en No context variables ##&hu Nincsennek konteksztusváltozók ##";
    const a = arr.map((e) => e.value || e);
    if (a.includes(data.value)) return true;
    return data.value + " ##&en Invalid in context ##&hu Nincs a kontextusban ##";
}

async function updateHandler(datum) {
  	//Ł(datum);
  	data.value = datum;
    const valid = isValid();
    emit("update:modelValue", datum, { valid, change: true });
}

async function refresh() {
    Object.assign(data, { value: props.modelValue });
}

defineExpose({ refresh });

onMounted(() => {
    const valid = isValid();
    if (!valid) updateHandler(undefined, { valid, change: true });
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "eventhandler-contextkey-select",
};
</script>
