<template>
    <document-view
        :bond="{ module: 'dataview', page: 'document', database: route.params.database, collection: route.params.collection, id: route.params.id }"
        :database="route.params.database"
        :collection="route.params.collection"
        :id="route.params.id"
        :version="route.params.version"
        :redirecting="true"
        :key="route.params.id + route.params.version"
    />
</template>

<script setup>
import DocumentView from "@/database-components/DocumentView.vue";

import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();
</script>

<script>
export default {
    path: "/document/:database/:collection/:id/:version?",
    name: "database-document",
    icon: "mdi-database",
    sort: 100,
    root: "Home",
};
</script>

<style scoped></style>
