<template>
    <v-combobox
        prepend-icon="mdi-pound"
        multiple
        chips
        :items="data.items"
        v-model="data.hashtags"
        rounded
        clearable
        variant="solo"
        closable-chips
        :rules="tagrules"
        @update:modelValue="tagupdate"
        :disabled="props.disabled"
        :hint="props.hint || ''"
      	:label="data.hashtags.length > 0 ? '' : (props.label || '#hashtags')"
        :flat="props.flat"
        :ref="getRef('component')"
    />
</template>
<script setup>
import { ref, reactive, watch, nextTick } from "vue";

const emit = defineEmits(["update:modelValue"]);
const props = defineProps(["modelValue", "disabled", "items", "flat", "hint", "label"]);

const data = reactive({
    items: [...(props.items || [])],
    hashtags: [ ...(props.modelValue || [])],
});

const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    Object.assign(data, { hashtags: [...(props.modelValue || [])] });
    await nextTick();
    if (refs.component.refresh) await refs.component.refresh();
}

defineExpose({ refresh });

const isValid = (e) => {
    if (e)
        return (
            e
                .map((str) => {
                    return /^#[a-z_0-9]+$/.test(str) || "##&en Invalid tag: ##&hu Érvénytelen tag: ##" + str;
                })
                .filter((b) => b !== true)[0] || true
        );
    else return true;
};
  
const tagrules = [isValid];

function updateValue() {
    emit("update:modelValue", data.hashtags || [], { valid: true, change: true});
};

function handlePunctuation(str) {
    str = str.toLowerCase();
    const fr = "áäâàãåčçćďéěëèêẽĕȇíìîïňñóöőòôõøðřŕšťúůüűùûýÿžþÞĐđßÆa·/_,:;";
    const to = "aaaaaacccdeeeeeeeeiiiinnoooooooorrstuuuuuuyyzbBDdBAa______";
    for (let i = 0, l = fr.length; i < l; i++) {
        str = str.replace(new RegExp(fr.charAt(i), "g"), to.charAt(i));
    }
    return "#" + str.replace(/[^a-z_0-9]/g, "");
}

const tagupdate = (arr) => {
  	Ł("tagupdate", arr);
    data.hashtags = arr
        .map((e) => e.split(" "))
        .flat()
        .map(handlePunctuation)
        .filter((e) => e.length > 2);
    updateValue();
};
</script>
