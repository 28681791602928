<template>
    <app-button :disabled :icon :label @click="clickOpen()" />
    <v-dialog v-model="dialog.open" width="auto">
        <v-card min-width="600">
            <v-card-actions>
                <span class="text-h5 pa-6">{{ props.label }}</span>
                <v-spacer />
                <v-btn size="large" @click="dialog.open = false" icon="mdi-close" />
            </v-card-actions>
            <v-card-text>
                <v-container>
                    <vuetiform-component :format :modelValue @update:modelValue="updateHandler" :bond :ref="getRef('component')" />
                </v-container>
            </v-card-text>
            <v-card-actions>
              	<app-button icon="mdi-close" label="##&en Cancel ##&hu Mégse ##" @click="data.dialog = false" />
                <v-spacer></v-spacer>
                <app-button :disabled="data.valid !== true" v-bind="props" icon="mdi-check" label="OK" @click="clickEnter()" />
            </v-card-actions>
            <!--pre>{{ data }}</pre-->
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick } from "vue";
import { isProxy, toRaw } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone as clone } from "../../helper-functions.mjs";

const props = defineProps(["format", "modelValue", "bond", "icon", "label", "disabled"]);
const emit = defineEmits(["update:modelValue"]);

const data = reactive({
    modelValue: props.modelValue,
    valid: true,
    change: false,
});

const dialog = reactive({
    open: false,
});

// :ref="getRef('component')"
const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    if (refs.component) if (refs.component.refresh) refs.component.refresh();
}

function updateHandler(value, nexus = {}) {
    data.valid = nexus.valid || true;
    data.modelValue = value;
    if (nexus.change) data.change = true;
}
function updateEmitter() {
    emit("update:modelValue", data.modelValue, { valid: data.valid, change: data.change });
}

function clickOpen() {
    data.valid = true;
    data.change = false;
    data.modelValue = props.modelValue;
    dialog.open = true;
}

function clickEnter() {
    dialog.open = false;
    updateEmitter();
}
defineExpose({ refresh });
</script>
<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-dialog",
};
</script>
