<template>
    <v-card v-resize="onResize">
        <v-container fluid>
            <v-row no-gutters>
                <v-col cols="12" sm="12" md="4" xl="3">
                    <p class="pa-6">
                        <v-btn color="primary" @click="update()"> {{ data.lastupdate }} </v-btn>
                    </p>
                </v-col>
                <v-col cols="12" sm="12" md="4" xl="3">
                    <p class="pa-6">
                        <v-btn color="primary" @click="expandall()" :disabled="data.expand"> ##&en EXPAND ##&hu Feltárás ##</v-btn>
                        <v-btn color="primary" @click="expandall()" :disabled="!data.expand"> ##&en CONDENSE ##&hu Összezárás ##</v-btn>
                    </p>
                </v-col>
                <v-col cols="12" sm="12" md="4" xl="3">
                    <v-select class="pa-3" label="Checkpoints" :items="Object.keys(data.checkpoints)" multiple v-model="data.selected" @update:modelValue="update"></v-select>
                </v-col>
                <v-col cols="12" sm="12" md="4" xl="3">
                    <v-select class="pa-3" label="Update Interval Seconds" :items="[0, 1, 2, 3, 5, 10, 20, 40, 60]" v-model="data.interval" @update:modelValue="update"></v-select>
                </v-col>
            </v-row>
        </v-container>
        <v-card class="overflow-y-auto" :height="resizedHeight">
            <v-expansion-panels v-model="data.panels" multiple>
                <v-expansion-panel v-for="(x, i) in getList()" :key="i">
                    <template v-slot:title>
                        <div class="font-weight-bold" :class="x.tags">
                            <pre>{{ getHead(x) }}</pre>
                        </div>
                    </template>
                    <template v-slot:text>
                        <div :class="x.tags">
                            <pre>{{ x.text }}</pre>
                        </div>
                        <v-btn v-if="x.uri" block @click="router.push(x.uri)" variant="elevated" class="ma-1" min-width="200" color="primary">
                            <p style="text-transform: lowercase">{{ x.uri }}</p>
                        </v-btn>
                    </template>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-card>
    </v-card>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const openCollection = () => {
    router.push("/collection/" + data.database + "/" + data.collection);
};

const data = reactive({
    checkpoints: {}, // object
    selected: null, // array
    interval: 0,
    lastupdate: 0,
    list: [],
    expand: false,
    panels: [],
});

const resizedHeight = ref(0);
const onResize = () => {
    resizedHeight.value = window.innerHeight - 210;
};
const getList = () => {
    return data.list;
};

const getHead = (x) => {
    return x.time.toString().padStart(6) + " ms " + now(x.date) + " " + x.index.toString().padStart(3) + " " + x.thread + " " + x.module.padStart(16) + " :: " + x.head;
};

const update = () => {
    $socket.emit("checkpoints:Checkpoints:update", { selected: data.selected }, (o) => {
        data.list.length = 0;
        Object.assign(data, o);
    });
};

const expandall = () => {
    data.expand = !data.expand;

    if (data.expand) data.panels = Array.from(Array(data.list.length).keys());
    else data.panels.length = 0;
};

let intervalId;

onMounted(async () => {
    await $connection();

    update();

    let t = 0;
    intervalId = setInterval(() => {
        t++;
        if (data.interval > 0) if (t % data.interval === 0) update();
    }, 1000);
});

onUnmounted(async () => {
    clearInterval(intervalId);
});

function now(date) {
    date = new Date(date);

    let month = date.getMonth() + 1;
    if (month < 10) month = "0" + month;

    let day = date.getDate();
    if (day < 10) day = "0" + day;

    let hour = date.getHours();
    if (hour < 10) hour = "0" + hour;

    let min = date.getMinutes();
    if (min < 10) min = "0" + min;

    let sec = date.getSeconds();
    if (sec < 10) sec = "0" + sec;

    return date.getFullYear() + "-" + month + "-" + day + " " + hour + ":" + min + ":" + sec;
}
</script>
<script>
export default {
    path: "/checkpoints",
    name: "Checkpoints",
    icon: "mdi-checkbox-multiple-marked-circle-outline",
    sort: 1900,
    root: "Home",
};
</script>
<style scoped>
.pointer {
    cursor: pointer;
}
.error {
    color: red;
}
.notice {
    color: orangered;
}
.message {
    color: green;
}
</style>
