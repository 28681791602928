<template>
    <div v-if="!data.initComplete" class="loading-container">
        <v-container class="fill-height" fluid>
            <v-row justify="center" align="center">
                <v-progress-circular indeterminate size="64"> </v-progress-circular>
            </v-row>
        </v-container>
    </div>
    <div v-else id="home">
        <div v-if="!socket.connected" class="full-width">
            <v-overlay v-model="data.showOverlay" persistent>
                <div v-if="socket.disconnected">
                    <v-alert class="ma-4 pa-8"
                        closable
                        @click:close="data.showOverlay = false"
                        type="error"
                        title="##&en Disconnected ##&hu Szétkapcsolva ##"
                        text="##&en Connection to the application host is lost. ##&hu Az alkalmzásnak kapcsolata a kiszolgálójával megszakadt. ##"
                    ></v-alert>
                </div>
                <div v-else>
                    <v-alert class="ma-4 pa-8"
                        closable
                        @click:close="data.showOverlay = false"
                        type="error"
                        title="##&en Closed ##&hu Zárolva ##"
                        text="##&en Connection to the application host has been closed. ##&hu Az alkalmzásnak kapcsolata a kiszolgálójával lezáródott. ##"
                    ></v-alert>
                </div>
            </v-overlay>
        </div>
        <div>
            <v-navigation-drawer v-model="drawer">
                <LanguageListItem />
                <ThemeSelector />
                <Navigation />
            </v-navigation-drawer>
            <v-navigation-drawer v-model="drawer" location="right">
                <Sidebar />
            </v-navigation-drawer>

            <v-app-bar>
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
                <Shortcuts />
                <v-spacer />
                <ApplicationSnackbars />
                <ApplicationPretext />
                <v-spacer />
                <SearchDialog />
            </v-app-bar>

            <SystemSnackbars />
            <div style="padding-top: 0px">
                <router-view />
            </div>
        </div>
    </div>
</template>

<script setup>
import SystemSnackbars from "@/application-components/SystemSnackbars.vue";
import ApplicationSnackbars from "@/application-components/ApplicationSnackbars.vue";
import ApplicationPretext from "@/application-components/ApplicationPretext.vue";
import Navigation from "@/application-components/Navigation.vue";
import Shortcuts from "@/application-components/Shortcuts.vue";
import Sidebar from "@/application-components/Sidebar.vue";
import ThemeSelector from "@/application-components/ThemeSelector.vue";

import { ref, onMounted, reactive } from "vue";

import { useSessionStore } from "@/store/sessionStore.mjs";
const sessionStore = useSessionStore();

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import { useSocketdataStore } from "@/store/socketdataStore.mjs";
const socket = useSocketdataStore();

import LanguageListItem from "@/multilanguage-components/LanguageListItem.vue";
import SearchDialog from "@/application-components/SearchDialog.vue";

const drawer = ref(true);
const data = reactive({
    initComplete: false,
    showOverlay: true,
});

onMounted(async () => {
    await sessionStore.initialization;
    const session = sessionStore.session;

    $socket.on("connect", () => {
        $socket.emit("route-change", route.fullPath);
    });

    if (!session._id) return;
    $socket.connect();

    $socket.on("redirect", (to) => {
        return router.push(to);
    });

    router.afterEach((to, from) => {
        $socket.emit("route-change", to.fullPath);
    });
  
    //await socket.initialization;
	while (!$socket.connected) await new Promise((resolve) => setTimeout(resolve, 100));
  
    $socket.emit("route-change", route.fullPath);

    data.initComplete = true;

});
</script>

<style scoped>
#home {
    overflow-y: scroll;
    height: calc(100vh - 64px);
}

.loading-container {
    position: fixed; /* or absolute, depending on your needs */
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.full-width {
    width: 100%; /* This ensures the div takes the full width of its parent */
}
</style>