<template>
    <!--p class="ma-2 pl-2 text-h6" v-if="props.label">{{ props.label }}</p-->
    <vuetiform-component
        v-if="data.format"
        :bond="props.bond"
        :format="data.format"
        :modelValue="data.modelValue"
        @update:modelValue="updateHandler"
        :ref="getRef('component')"
        :key="data.key"
      	:label
    />
</template>

<script setup>
// dynamically load data from socket.io

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { compare } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, onUnmounted, toRaw } from "vue";

const Đ = (msg, ...args) => {
    Ł("$$$", msg, JSON.stringify(...args));
};

function clone(p) {
    return structuredClone(toRaw(p));
}

const props = defineProps(["bond", "format", "modelValue", "identifier", "module", "channel", "label", "updateOn", "serverform"]);
const emit = defineEmits(["update:modelValue"]);

const data = reactive({ format: props.format, modelValue: props.modelValue, valid: true, key: 0 });

const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

function refresh() {
    Đ("refresh");
    serverUpdate("refresh");
}
defineExpose({ refresh });

async function refreshComponent() {
    await nextTick();
    if (!refs.component) return;
    if (!refs.component.refresh) return;
  Đ("refreshComponent");
    refs.component.refresh();
}


function updateValue(datum, nexus = { valid: true, change: true }) {
  	data.modelValue = datum;
    data.valid = nexus.valid;
    Đ("updateValue", { datum });
    emit("update:modelValue", datum, { valid: data.valid, change: nexus.change });
    return datum;
}

function updateHandler(datum, nexus = { valid: true, change: false }) {
    updateValue(datum, nexus);
    serverUpdate("updateHandler", nexus);
}
  
function updateEmitter(datum = data.modelValue, nexus = { valid: true, change: false }) {
    emit("update:modelValue", datum, { valid: data.valid, change: nexus.change });
}
  
/*
function formatUpdate(format) {
    Object.assign(data, { format });
}
//*/

///*
import formatFunctions from "@/vuetiform/formatFunctions.mjs";
function formatUpdate(_format) {
    data.format = formatFunctions.call(
        { __from_VuetiformServerform_formatUpdate: true, Document: () => toRaw(props.bond?.document || {}), Format: () => toRaw(data.format) },
        _format,
    );
}
//*/

async function serverUpdate(event, nexus = { valid: true, change: false }) {
    $socket.emit(props.module + ":" + props.channel + ":update", { props, data, event }, (o) => {
      	Ł(o);
        if ("format" in o) formatUpdate(o.format);
        if ("modelValue" in o) updateValue(o.modelValue, o.nexus || nexus); //, { component: " VuetiformServerform", trigger: "serverUpdate", identifier: props.identifier });
        if (Object.keys(o).length > 0) refreshComponent();
        Đ("serverUpdated", o);
    });
}

onMounted(async () => {
    if (props.updateOn)
        $socket.on(props.updateOn, () => {
            serverUpdate(props.updateOn);
        });
    serverUpdate("onMounted");
});

onUnmounted(async () => {
    if (props.updateOn) $socket.removeListener(props.updateOn);
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-serverform",
};
</script>
