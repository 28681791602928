<template>
    <div :class="props.classes || 'text-h7 pa-2 ml-2'">
        <v-text-field
            ref="textField"
            v-model="data.value"
            :label="props.label"
            @input="updateHandler()"
            @update:modelValue="updateHandler()"
            :prepend-icon="props.icon || 'mdi-currency-usd'"
            append-icon="mdi-code-parentheses-box"
            clearable
        />
        <v-chip @click="extend('#')" class="ma-2"> #( ##&en Mathematical ##&hu Matematikai ## )</v-chip>
        <v-chip @click="extend('@')" class="ma-2"> @( ##&en Programmatical ##&hu Programozástechnikai ## )</v-chip>
        <v-chip v-for="(chip, i) in getItems()" @click="drop(chip)" class="ma-1">
            {{ chip.value }}
        </v-chip>
    </div>
</template>
<script setup>
import { isProxy, toRaw } from "vue";
import { ref, reactive, nextTick, onMounted } from "vue";
import { structuredClone as clone } from "../../helper-functions.mjs";
import { getCurrentInstance } from "vue";

import { useEventhandlerStore } from "@/store/eventhandlerStore.mjs";
const store = useEventhandlerStore();

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

const props = defineProps(["bond", "label", "classes", "modelValue", "items", "icon", "mandatory", "filters"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ value: clone(props.modelValue || "") });
const textField = ref(null); // Ref for the text field

async function refresh() {
    Object.assign(data, { value: clone(props.modelValue || "") });
    await nextTick();
    validHandler();
}

defineExpose({ refresh });

function drop(chip) {
    //if (isObject(data.value)) data.value = data.value.value;
    if (!data.value) data.value = "";
    else data.value += " ";
    data.value += chip.value;
    updateHandler();
}

function extend(char) {
    const input = textField.value.$el.querySelector("input");
    const selectedText = input.value.substring(input.selectionStart, input.selectionEnd);
    if (selectedText)
        if (isValid(selectedText)) {
            data.value = data.value.split(selectedText.trim()).join(" " + char + "( " + selectedText.trim() + " ) ");
            return;
        }
    //if (isObject(data.value)) data.value = data.value.value;
    if (!data.value) data.value = "";
    data.value = char + "( " + data.value + " )";
    updateHandler();
}

function getItems() {
    const unfiltered = store.getContextkeys(props.bond.stack);
    if (!props.filters) return unfiltered;
    const filters = props.filters.split("|");
    const filtered = unfiltered.filter((e) => filters.includes(e._type));
    return filtered;
}

function isValid(text) {
    if (!text) text = data.value;
    const items = getItems().map((e) => {
        if (isObject(e)) return e.value;
        return e;
    });

    if (props.mandatory) {
        if (text === undefined) return props.label + " - ##&en Mandatory field ##&hu kötelező mező ##";
        if (text === null) return props.label + " - ##&en Mandatory field, null value ##&hu kötelező mező, null érték ##";
        if (text.length < 2) return props.label + " - ##&en too short ##&hu túl rövid ##";
    }

    if (text === null) return true;

    const regex = /\$[\w.]+/g;
    const values = text.match(regex);

    if (!values) return true;
    //const every = values.every((match) => items.includes(match));
    for (const match of values) if (!items.includes(match)) return match + " ##&en is not available the context ##&hu nem elérhető a kontextusban ##";
    return true;
}

async function updateHandler() {
    await nextTick();
    let datum = clone(data.value);
    // combobox can return strings or objects
    //if (isObject(datum)) datum = clone(data.value.value);
    emit("update", datum);
    emit("update:modelValue", datum);
    validHandler();
}

function validHandler() {
    emit("update:valid", isValid());
}

onMounted(updateHandler);
</script>

<script>
export default {
    inheritAttrs: false,
    name: "eventhandler-contextkey-solvable-text",
};
</script>
