<template>
    <v-card flat>
        <v-row class="ma-4">
            <v-col cols="auto" class="pa-2">
                <v-btn color="primary" density="default" icon="mdi-chevron-left" @click="foxaction('back')"></v-btn>
            </v-col>
            <v-col cols="auto" class="pa-2">
                <v-btn color="primary" density="default" icon="mdi-home" @click="foxaction('home')"></v-btn>
            </v-col>
            <v-col cols="auto" class="pa-2">
                <div class="text-subtitle-1 pa-2">{{ data.navigation }}</div>
            </v-col>
        </v-row>
        <v-row class="ma-4">
            <v-col cols="auto">
                <div class="text-h4 pa-2">{{ data.headline }}</div>
            </v-col>
        </v-row>
    </v-card>

    <div v-for="(action, k, i) in data.actions">
        <v-btn color="primary" block size="x-large" @click="clickHandler(k)"> {{ action }} </v-btn>
    </div>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const data = reactive({
    actions: null,
});

const foxaction = (on) => {
    $socket.emit("foxactions-" + on, ({ actions, headline, navigation }) => {
        data.actions = actions;
        data.headline = headline;
        data.navigation = navigation;
    });
};

const clickHandler = (actionkey) => {
    $socket.emit("foxactions-click", { actionkey }, ({ actions, headline, navigation }) => {
        data.actions = actions;
        data.headline = headline;
        data.navigation = navigation;
    });
};

onMounted(async () => {
    await $connection();

    foxaction("get");
});
</script>

<style scoped></style>
