import { defineStore } from "pinia";
import { reactive } from "vue";

export const useQueryStore = defineStore("query-store", () => {
    //const query = reactive({});
    //const format = reactive({});
    const expressions = reactive({});

    function assign(o) {
        //Object.assign(query, o.query);
        //Object.assign(format, o.format);
        if (o.expressions) Object.assign(expressions, o.expressions);
    }

    return { assign /*query, format*/ };
});
