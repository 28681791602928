export function timespanSections(timefrom_ms, timespan_ms, WIDTH, HEIGHT) {
    let now = new Date();

    const timezoneOffset_ms = now.getTimezoneOffset() * 60000;
    const gmtime = timefrom_ms - timezoneOffset_ms;

    function getDate(n) {
        const date = new Date();
        date.setTime(n + timezoneOffset_ms);
        return date;
    }

    function displayMinutes(n) {
        const date = getDate(n);

        let hour = date.getHours();
        if (hour < 10) hour = "0" + hour;

        let min = date.getMinutes();
        if (min < 10) min = "0" + min;

        let sec = date.getSeconds();
        if (sec < 10) sec = "0" + sec;

        return ":" + min + ":" + sec;
    }

    function displaySeconds(n) {
        const date = getDate(n);
        let sec = date.getSeconds();
        if (sec < 10) sec = "0" + sec;
        return ":" + sec;
    }

    function isCurrentSecond(n) {
        const date = getDate(n);
        if (Math.abs(date.getTime() / 1000 - new Date().getTime() / 1000) < 0.5) return true;
        return false;
    }

    function timespanSlices(unit, mark, step, formatter) {
        const a = [];

        // there is a pre-field, from the friction of the first step
        const slot = WIDTH / timespan_ms;
        const width = slot * step;
        const pres = gmtime % step;
        const prew = width - slot * pres;

        {
            // this is the scope for the first friction / or the first element
            const o = {};
            const t = gmtime - pres;
            o.t = t;
            o.title = displaySeconds(t);
            o.width = width; //prew;
            o.even = t % mark === 0;
            o.x = prew - width;
            if (t % unit === 0) o.label = formatter(t);
            a.push(o);
        }

        let x = prew;
        for (let i = 0; i <= timespan_ms; i += step) {
            const o = {};
            const t = gmtime + i + step - pres;
            o.t = t;
            o.title = displaySeconds(t);
            o.width = width;
            if (isCurrentSecond(t)) o.now = true;
            o.even = t % mark === 0;
            o.x = x;
            if (t % unit === 0) o.label = formatter(t);
            a.push(o);
            x += width;
        }

        return a;
    }

    if (timespan_ms <= 15000) return timespanSlices(1000, 2000, 1000, displaySeconds);
    if (timespan_ms <= 60000) return timespanSlices(2000, 2000, 1000, displaySeconds);
    if (timespan_ms <= 120000) return timespanSlices(5000, 2000, 1000, displaySeconds);

    if (timespan_ms < 300000) return timespanSlices(10000, 10000, 5000, displaySeconds);

    if (timespan_ms <= 450000) return timespanSlices(20000, 20000, 10000, displayMinutes);
    if (timespan_ms <= 600000) return timespanSlices(60000, 20000, 10000, displayMinutes);

    return [];
}
