<template>
    <v-dialog v-model="dialog.open" width="auto">
        <v-card min-width="600">
            <v-card-actions>
                <span class="text-h5 pa-6">{{ data.label }}</span>
                <v-spacer />
                <v-btn size="large" @click="dialog.open = false" icon="mdi-close" />
            </v-card-actions>
            <v-card-text>
                <v-container v-if="data.html" class="pa-6">
                    <div v-html="data.html"></div>
                </v-container>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn class="bg-primary" size="large" icon @click="dialog.open = false">
                    <v-icon>mdi-check</v-icon>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick } from "vue";
import { isProxy, toRaw } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const dialog = reactive({
    open: false,
});

const data = reactive({});

onMounted(async () => {
    await $connection();

    $socket.on("application-pretext", (o) => {
        Object.assign(data, o);
        dialog.open = true;
    });
});
</script>
<style scoped>
#snack:hover {
    cursor: pointer;
}
</style>
