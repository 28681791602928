<template>
    <wall-layout module="community" :identifier="community" />
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, toRaw } from "vue";

import WallLayout from "@/wall-components/WallLayout.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

let community;
if (route.params.community.length > 1) community = route.params.community;
</script>
<script>
export default {
    path: "/community/:community?",
    link: "/community",
    name: "##&en Community Wall ##&hu Közösségi Fal ##",
    icon: "mdi-forum",
    root: "Home",
};
</script>
<style scoped></style>
