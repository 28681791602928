<template>
    <div id="selector-content">
        <selector-component :structure="data.structure" @leftClickElement="leftClickElement" @rightClickElement="rightClickElement" :key="route.params.database" />
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, reactive } from "vue";
import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const data = reactive({
    structure: {},
});

const leftClickElement = (e) => {
    router.push("/database/" + e.database + "/" + e.collection);
};
const rightClickElement = (e) => {
    window.open("/database/" + e.database + "/" + e.collection, "_blank");
};

function init() {
    //const database = route.params.database;
    $socket.emit("dataview:Datapoints:init", {}, (o) => {
        Object.assign(data, o);
    });
}

onMounted(async () => {
    await $connection();
    init();

    $socket.on("dataview:Datapoints:update", (o) => {
        Object.assign(data, o);
    });
});
onUnmounted(async () => {
    $socket.removeListener("dataview:Datapoints:update");
});
</script>
<script>
export default {
    path: "/datapoints/:database?",
    name: "##&en Datapoints ##&hu Adatpontok ##",
    icon: "mdi-database-settings-outline",
    root: "Home",
};
</script>
<style scoped>
#selector-content {
    padding: 24px;
    padding-bottom: 100px;
}
</style>
