<template>
    <v-container fluid>
        <v-container>
            <v-row>
                <v-col cols="12" md="2">
                    <v-card min-height="268">
                        <v-list>
                            <v-list-item
                                v-for="(c, i) of data.sections"
                                :key="i"
                                :title="c.title"
                                link
                                :active="c.value === data.identifier"
                                @click="router.push(c.link)"
                            ></v-list-item>
                        </v-list>
                    </v-card>
                </v-col>

                <v-col cols="12" md="8">
                    <v-sheet style="background-color: transparent" min-height="70vh">
                        <v-btn block style="text-transform: none" color="primary" @click="data.dialog = true" :disabled="data.readonly" :color="data.readonly ? 'gray' : 'primary'"
                            >##&en Add entry ##&hu Bejegyzés ##</v-btn
                        >
                        <v-dialog v-model="data.dialog" v-bind="props.dialog">
                            <wall-entry-card
                                :bond="{ identifier: data.identifier, id: data.document._id, module: props.module }"
                                :document="data.document"
                                :format="data.format"
                                @close="data.dialog = false"
                                @submit="submit"
                                :key="data.document._id"
                            />
                        </v-dialog>

                        <wall-stream :module="module" :identifier="data.identifier" :readonly="data.readonly" />
                    </v-sheet>
                </v-col>

                <v-col cols="12" md="2" v-if="(Object.keys(data.members) || []).length > 0">
                    <v-card min-height="268">
                        <v-list>
                            <v-list-item v-for="(v, k, i) of data.members" :key="i" link>{{ v }}</v-list-item>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
    </v-container>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, toRaw } from "vue";
//import { VInfiniteScroll } from "vuetify/labs/VInfiniteScroll";

import WallEntryCard from "@/vuetiform-cards/WallEntryCard.vue";
import WallStream from "@/wall-components/WallStream.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();

const props = defineProps(["module", "identifier"]);

const data = reactive({
    identifier: props.identifier,
    readonly: false,
    sections: [],
    dialog: false,
    document: {},
    format: {},
    members: {},
});

const submit = (document) => {
    data.dialog = false;
    $socket.emit(props.module + ":WallLayout:submission", { identifier: data.identifier, document }, (o) => {
        Object.assign(data, o);
    });
};

const init = (a) => {
    $socket.emit(props.module + ":WallLayout:init", { identifier: props.identifier }, (o) => {
        Object.assign(data, o);
    });
};

$socket.on(props.module + ":WallStreamUpdate", (o) => {
    Object.assign(data, o);
});

onMounted(async () => {
    await $connection();
    init();
});
</script>
