<template>
    <v-card v-if="data.error"><v-alert type="error" :title="data.error" icon="mdi-alert-circle" variant="tonal"></v-alert></v-card>
    <v-card v-else v-resize="onResize" class="pa-4 scrollable overflow-auto">
        <v-card-item class="pa-10">
            <v-container fluid>
                <v-row no-gutters>
                    <v-col cols="12" sm="12" md="8">
                        <document-info :database="database" :collection="collection" :id="id" :data="data" :document="data.document" :format="data.format" />
                    </v-col>
                    <v-col cols="12" sm="12" md="4">
                        <v-sheet class="flex-fill pa-2">
                            <hashtags-combobox
                                v-if="data.hashtags"
                                :modelValue="data.document._hashtags"
                                :items="data.hashtags"
                                class="ma-2"
                                @update:modelValue="hashtagsUpdate"
                                :ref="getRef('hashtags')"
                            />
                        </v-sheet>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-item>
        <v-container fluid color="rgba(0,0,0,0)">
            <v-btn
                v-if="!props.host || props.host === hostname"
                :disabled="props.collection === 'collections' || !data.defining_id"
                :color="props.collection === 'collections' ? 'gray' : 'primary'"
                @click="editCollectionFormat()"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database</v-icon>Format</p>
            </v-btn>
            <v-btn v-else :color="!props.host || props.host === hostname ? 'gray' : 'primary'" @click="openHostCollection()" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database</v-icon>Server</p>
            </v-btn>

            <v-btn @click="openCollection()" color="primary" variant="elevated" min-width="200" class="ma-1">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database</v-icon>##&en Collection ##&hu Kollekció ##</p>
            </v-btn>
            <v-btn
                @click.native="saveDocument"
                :disabled="data.valid !== true || data.document._readonly === true"
                :color="data.valid !== true || data.document._readonly === true ? 'gray' : 'primary'"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon class="pr-3">mdi-database-check</v-icon>##&en Save ##&hu Mentés ##</p>
            </v-btn>
            <v-btn
                @click="removeDocument()"
                :disabled="data.readonly || data.document._readonly || !data.deletable"
                :color="getRemoveColor(data.readonly || data.document._readonly || !data.deletable)"
                variant="elevated"
                class="ma-1"
                min-width="200"
            >
                <p style="text-transform: none"><v-icon class="pr-3">mdi-delete</v-icon>##&en Delete ##&hu Törlés ##</p>
            </v-btn>
            <v-btn @click.native="openDocument(data.prev)" :disabled="!data.prev" :color="!data.prev ? 'gray' : 'primary'" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-chevron-left</v-icon>##&en Previous ##&hu Előző ##</p>
            </v-btn>
            <v-btn @click.native="openDocument(data.next)" :disabled="!data.next" :color="!data.next ? 'gray' : 'primary'" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-chevron-right</v-icon>##&en Next ##&hu Következő ##</p>
            </v-btn>
            <DocumentVersions
                v-if="data.versions.length > 1"
                :bond="props.bond"
                :database="database"
                :collection="collection"
                :id="id"
                :version="data.version"
                :versions="data.versions"
                :key="data.version"
            />
        </v-container>
        <v-card-text>
            <pre v-if="data.invalid" class="pb-2" :class="{ 'text-orange': true }">{{ data.invalid.join("! ") }}</pre>
            <pre class="pb-2" :class="{ 'text-red': data.valid !== true }">{{ data.valid !== true ? data.valid : "-" }}</pre>
            <vuetiform-component
                :format="{ is: 'vuetiform-poliform', format: data.format, label: 'Document' }"
                :bond="getBond()"
                :modelValue="data.document"
                :key="data.document._id + '.' + data.key"
                :disabled="data.readonly || data.document._readonly"
                @update:modelValue="updateHandler"
                :ref="getRef('component')"
            />
        </v-card-text>
        <div>
            <v-btn flat icon="mdi-triangle-small-down" size="small" @click.native="data.debug = !data.debug" style="background-color: transparent" />
            <div v-if="data.debug">
                <pre>{{ data }}</pre>
            </div>
        </div>
    </v-card>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch, nextTick, toRaw } from "vue";
import { compare } from "../../helper-functions.mjs";
import { structuredClone } from "../../helper-functions.mjs";
function clone(p) {
    return structuredClone(toRaw(p));
}
  
const Đ = (msg, ...args) => {
    Ł("===", msg, JSON.stringify(...args));
};
  
const props = defineProps(["bond", "database", "collection", "id", "version", "redirecting", "format"]);

const database = props.database;
const collection = props.collection;
const id = props.id;
const version = props.version;

const hostname = (ß.HOSTNAME || "localhost").split(".")[0];

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

import DocumentInfo from "@/database-components/DocumentInfo.vue";
import DocumentVersions from "@/database-components/DocumentVersions.vue";

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import HashtagsCombobox from "@/vuetiform-components/HashtagsCombobox.vue";

const openDocument = (id) => {
    router.push("/document/" + database + "/" + collection + "/" + id);
};

const openCollection = () => {
    router.push("/database/" + database + "/" + collection);
};
const openHostDocument = () => {
    return (window.location.href = "https://" + data.host + "/document/" + database + "/" + collection + "/" + data.document._id);
};

const editCollectionFormat = () => {
    router.push("/document/" + database + "/collections/" + data.defining_id);
};

const data = reactive({
    document: {},
    format: null,
    database,
    collection,
    pattern: null,
    invalid: [],
    valid: true,
    readonly: true,
    deletable: false,
    host: null,
    debug: true, // false;
    createdBy: null,
    updatedBy: null,
    hashtags: null,
    error: null,
    prev: null,
    next: null,
    removal: false,
    versions: [],
    version: null,
    key: 0,
    defining_id: null,
    ctrl: false,
    shift: false,
    alt: false,
});

import formatFunctions from "@/vuetiform/formatFunctions.mjs";
async function formatUpdate(_format) {
    const reformat = await formatFunctions.call({ __from_DocumentView_formatUpdate: true, Document: () => toRaw(data.document), Format: () => toRaw(data.format) }, _format);
    Object.assign(data, { format: reformat });
}

async function updateHandler(patch, nexus = { valid: true, change: false }) {
    //Ł(":: updateHandler", nexus.change);

    data.valid = nexus.valid;

    if (!nexus.change) {
        //Ł(":: No Change marker, patching");
        return Object.assign(data.document, patch);
    }
    const original = clone(toRaw(data.document));
    const patched = Object.assign({}, data.document, patch);
    const identical = compare(original, patched);

    if (identical) {
        //Ł(":: No Change compared, identical data");
        return Object.assign(data.document, patch);
    }

    //Ł(":: Changer", original, "----->", patched);

    changeHandler(patched);
}

function changeHandler(document) {
    Đ("changeHandler", { document });
    $socket.emit("dataview:DocumentView:change", { database, collection, document }, async ({ document, format, ...o } = {}) => {
        Đ("dataview:DocumentView:change", { document });
        //Object.assign(data, o);
        //if (format) await formatUpdate(format);
        if (document) documentChange(document);
    });
}

function documentChange(document) {
    data.document = document;
    refreshComponent();
}

function documentUpdate(document) {
    //Ł(":: documentUpdate", document);
    //Object.assign(data, { document });
    data.document = document;
    //refreshComponent();
}

async function hashtagsUpdate(_hashtags = []) {
    if (_hashtags.length > 0) data.document._hashtags = _hashtags;
    else delete data.document._hashtags;
    //await formatUpdate(format);
    return refreshComponent();
}

function getBond() {
    return { stack: [], ...props.bond, document: data.document };
}

const saveDocument = (e) => {
    $socket.emit("dataview:DocumentView:saveDocument", { database, collection, document: { _id: id, ...data.document } }, ({ uri } = {}) => {
        if (e.altKey) return update();
        if (uri) return router.push(uri);
        if (props.redirecting) router.push("/database/" + database + "/" + collection);
    });
};

const getRemoveColor = (disabled) => {
    if (disabled) return "gray";
    if (data.removal) return "red";
    return "primary";
};

const removeDocument = () => {
    if (!data.removal) {
        data.removal = true;
        setTimeout(() => {
            data.removal = false;
        }, 2000);
        return;
    }
    $socket.emit("dataview:DocumentView:removeDocument", { database, collection, document: data.document }, ({ uri } = {}) => {
        if (uri) return router.push(uri);
        if (props.redirecting) router.push("/database/" + database + "/" + collection);
    });
};

const resizedHeight = ref(0);
const onResize = () => {
    //resizedHeight.value = window.innerHeight - 10;
};

const update = () => {
    /*Ł(
        ":: update",
        ((data.document || {}).datafields || []).map((e) => e.align),
    );*/
    $socket.emit("dataview:DocumentView:update", { database, collection, id, version }, async ({ document, format, ...o } = {}) => {
        Object.assign(data, o);
        if (format) await formatUpdate(format);
        if (document) documentUpdate(document);
    });
};

// :ref="getRef('component')"
const refs = reactive({
    component: null,
    hashtags: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refreshComponent() {
    await nextTick();

    Đ("refreshComponent", { "data.document": data.document });

    if (refs.component) if (refs.component.refresh) refs.component.refresh();
    if (refs.hashtags) if (refs.hashtags.refresh) refs.hashtags.refresh();
}

function handleKeyDown(event) {
    if (data.ctrl || data.alt)
        if (event.key === "s") {
            event.preventDefault();
            saveDocument({ altKey: true });
        }

    if (event.key === "Alt") data.alt = true;
    if (event.key === "Shift") data.shift = true;
    if (event.key === "Meta" || event.key === "Ctrl") data.ctrl = true;
}
function handleKeyUp(event) {
    if (event.key === "Alt") data.alt = false;
    if (event.key === "Shift") {
        data.shift = false;
        data.lastShiftClick = null;
    }
    if (event.key === "Meta" || event.key === "Ctrl") data.ctrl = false;
}

onMounted(async () => {
    document.addEventListener("keydown", handleKeyDown);
    document.addEventListener("keyup", handleKeyUp);
    await $connection();
    //await formatUpdate(props.format || {});
    update();
});

onUnmounted(() => {
    document.removeEventListener("keydown", handleKeyDown);
    document.removeEventListener("keyup", handleKeyUp);
});
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
/* Scrollable in a dialog*/
.scrollable {
    /*overflow-y: scroll;*/
    height: 100%;
}
</style>
