<template>
    <v-dialog v-model="dialog.open" width="auto" :persistent="data.latestversion !== data.version">
        <v-card min-width="600">
            <v-card-actions>
                <span class="text-h5 pa-6">{{ displayVersion(data.version) }} {{ data.label }}</span>
                <v-spacer />
                <v-btn v-if="data.latestversion === data.version" size="large" @click="dialog.open = false" icon="mdi-close" />
            </v-card-actions>
            <v-card-text>
                <span class="text-h7 pa-2">{{ displayDate(data.execid) }} </span>
                <div v-if="data.init" class="pa-4">
                    <pre class="pa-1" v-for="(v, k, i) in data.init">{{ k }} = {{ JSON.stringify(v, null, 4) }}</pre>
                </div>
                <v-sheet v-for="(step, i) in data.steps" :class="{ select: data.panel === i }">
                    <div class="font-weight-bold pa-1 ma-1 pointer" :class="{ alert: step.alert }" @click="select(i)">
                        <pre>#{{ i + 1 }} {{ step.name }} {{ step.enty ? "..." : "" }}</pre>
                    </div>
                    <div v-if="step.entry" class="pa-1" :class="{ hidden: data.panel !== i }">
                        <pre class="pa-1" v-for="(v, k, i) in step.entry">{{ k }} = {{ JSON.stringify(v, null, 4) }}</pre>
                    </div>
                </v-sheet>
                <div v-if="data.returns" class="pa-4">
                    <pre class="pa-1" v-for="(v, k, i) in data.returns">{{ k }} = {{ JSON.stringify(v, null, 4) }}</pre>
                </div>
            </v-card-text>
            <v-card-actions>
                <v-btn
                    v-if="data.version !== data.latestversion"
                    color="primary"
                    variant="elevated"
                    class="ma-1"
                    min-width="200"
                    @click.native="
                        dialog.open = false;
                        router.push({ params: { version: data.version, execid: '' } });
                    "
                >
                    <p style="text-transform: none"><v-icon>mdi-backup-restore</v-icon> ##&en Checkout ##&hu Megtekintés ## {{ displayVersion(data.version) }}</p>
                </v-btn>
                <v-spacer></v-spacer>

                <v-btn
                    color="primary"
                    variant="elevated"
                    class="ma-1"
                    min-width="200"
                    @click.native="
                        dialog.open = false;
                        router.push({ params: { version: data.latestversion, execid: '' } });
                    "
                >
                    <p style="text-transform: none"><v-icon class="pr-3">mdi-progress-clock</v-icon>##&en Latest version ##&hu Utolsó verzió ##</p>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch, nextTick } from "vue";
import { isProxy, toRaw } from "vue";
import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const dialog = reactive({
    open: false,
});

const data = reactive({
    steps: [],
    panel: -1,
    returns: {},
    latestversion: "?",
    version: "?",
});

function select(i) {
    if (data.panel === i) return (data.panel = -1);

    data.panel = i;
}

function displayVersion(vid) {
    if (!vid) return "";
    return "v" + parseInt(vid.slice(-6), 16);
}

const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
};

function displayDate(id) {
    if (!id) return "";
    const date = new Date(parseInt(id.substring(0, 8), 16) * 1000);
    const formatter = new Intl.DateTimeFormat("##&en en-US ##&hu hu-HU ##", options);
    return formatter.format(date);
}

onMounted(async () => {
    await $connection();

    $socket.on("eventhandlereditor:ExecutionFeedback:dialog", (o) => {
        data.panel = -1;
        dialog.open = true;
        Object.assign(data, o);
    });
});
</script>
<style scoped>
#snack:hover {
    cursor: pointer;
}
.alert {
    color: red;
    font-weight: bold;
    background-color: rgba(255, 0, 0, 0.2) !important;
}
.select {
    background-color: rgba(var(--v-theme-primary), 0.3) !important;
}
.hidden {
    display: none;
}
.pointer {
    cursor: pointer;
}
</style>
