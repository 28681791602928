<template>
    <UserHome v-if="sessionStore.session._id" />
    <LandingPage v-else />
</template>

<script setup>
import UserHome from "@/application-components/UserHome.vue";
import LandingPage from "@/application-components/LandingPage.vue";

import { ref, onMounted, reactive } from "vue";

import { useSessionStore } from "@/store/sessionStore.mjs";
const sessionStore = useSessionStore();

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

onMounted(async () => {
    await sessionStore.initialization;
});
</script>

<script>
export default {
    path: "/",
    name: "Home",
    icon: "mdi-home",
    sort: 100,
};
</script>


