<template>
    <v-dialog width="auto">
        <template v-slot:activator="{ props }">
            <v-btn v-bind="props" :color="version !== (versions || []).slice(-1)[0] ? 'red' : 'primary'" variant="elevated" class="ma-1" min-width="200">
                <p style="text-transform: none"><v-icon>mdi-history</v-icon> ##&en Versioning ##&hu Verzionálás ##</p>
            </v-btn>
        </template>

        <template v-slot:default="{ isActive }">
            <v-card>
                <v-card-actions>
                    <v-card-title>##&en Document history ##&hu Dokumentum története ##</v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn icon="mdi-close" @click="isActive.value = false"></v-btn>
                </v-card-actions>
                <v-card-text>
                    <v-list lines="one">
                        <v-list-item
                            v-for="v in props.versions"
                            :key="v"
                            :title="displayItem(v)"
                            @click="data.selected = v"
                            :active="data.selected === v"
                            :disabled="v === props.version"
                        ></v-list-item>
                    </v-list>
                </v-card-text>
                <v-card-actions>
                    <v-btn
                        v-if="route.params.version !== (props.versions.slice(-1)[0] || '')"
                        :disabled="(props.versions || []).length < 1"
                        @click="latest(isActive)"
                        color="primary"
                        variant="elevated"
                        min-width="200"
                        class="ma-1"
                    >
                        <p style="text-transform: none"><v-icon class="pr-3">mdi-progress-clock</v-icon>##&en Latest version ##&hu Utolsó verzió ##</p>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                        :disabled="!data.selected || data.selected === props.version"
                        text="##&en Open ##&hu Megnyitás ##"
                        @click="open(isActive)"
                        :color="!data.selected || data.selected === props.version ? 'gray' : 'primary'"
                        variant="elevated"
                        min-width="200"
                        class="ma-1"
                    >
                        <p style="text-transform: none"><v-icon class="pr-3">mdi-restore</v-icon>##&en Checkout ##&hu Visszaállítás ##</p>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </template>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";
const props = defineProps(["bond", "database", "collection", "id", "version", "versions", "options"]);

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const database = props.database;
const collection = props.collection;
const id = props.id;
const version = props.version;
const versions = props.versions;

const data = reactive({
    selected: props.version,
});

const options = props.options || {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
};

function displayItem(vid) {
    const date = new Date(parseInt(vid.substring(0, 8), 16) * 1000);
    const formatter = new Intl.DateTimeFormat("##&en en-US ##&hu hu-HU ##", options);
    return "v" + parseInt(vid.slice(-6), 16) + " " + formatter.format(date);
}

function displayVersion(vid) {
    return "v" + parseInt(vid.slice(-6), 16);
}

function open(isActive) {
    router.push("/" + (props.bond.page || "document") + "/" + database + "/" + collection + "/" + id + "/" + data.selected);
    isActive.value = false;
}
function latest(isActive) {
    router.push("/" + (props.bond.page || "document") + "/" + database + "/" + collection + "/" + id + "/" + (props.versions.slice(-1)[0] || ""));
    isActive.value = false;
}

onMounted(async () => {
    await $connection();
});
</script>

<style scoped>
.pointer {
    cursor: pointer;
}
.scrollable {
    height: 100%;
}
</style>
