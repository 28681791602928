// main files with {app} argument 

// /srv/vamm-project/@vue3-modules/app/vue3/main.appbutton.mjs
import file_0 from '/src/main.appbutton.mjs';

// /srv/vamm-project/@vue3-modules/codemirror/vue3/main.codemirror.mjs
import file_1 from '/src/main.codemirror.mjs';

// /srv/vamm-project/@vue3-modules/socketio/vue3/main.socketio.mjs
import file_2 from '/src/main.socketio.mjs';

// /srv/vamm-project/@vue3-modules/pinia/vue3/main.pinia.mjs
import file_3 from '/src/main.pinia.mjs';

// /srv/vamm-project/@vue3-modules/router/vue3/main.router.mjs
import file_4 from '/src/main.router.mjs';

// /srv/vamm-project/@vue3-modules/splitpanes/vue3/main.splitpanes.mjs
import file_5 from '/src/main.splitpanes.mjs';

// /srv/vamm-project/@vue3-modules/vite/vue3/main.betas.mjs
import file_6 from '/src/main.betas.mjs';

// /srv/vamm-project/@vue3-modules/vite/vue3/main.components.mjs
import file_7 from '/src/main.components.mjs';

// /srv/vamm-project/@vue3-modules/vite/vue3/main.debuglog.mjs
import file_8 from '/src/main.debuglog.mjs';

// /srv/vamm-project/@vue3-modules/vue/vue3/main.vue.mjs
import file_9 from '/src/main.vue.mjs';

// /srv/vamm-project/@vue3-modules/vuetify/vue3/main.plugins.mjs
import file_10 from '/src/main.plugins.mjs';

const imports = { file_0, file_1, file_2, file_3, file_4, file_5, file_6, file_7, file_8, file_9, file_10 };

export default function(a) {     	
	for (const i of Object.keys(imports).sort()) imports[i](a);
	}