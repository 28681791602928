<template>
    <div v-for="(definition, key, i) in store.format">
        <vuetiform-component :key="i" :format="definition" :modelValue="data[key]" @update="(...v) => updateHandler(key, ...v)" :disabled="props.disabled" />
        <div v-if="definition.formats" v-for="(subformat, selector, j) in definition.formats" :key="j">
            <div v-if="data[key] === selector">
                <div v-for="(subdefinition, subkey, k) in subformat">
                    <vuetiform-component
                        :key="i + '_' + j + '_' + k"
                        :format="subdefinition"
                        :modelValue="data[subkey]"
                        @update:modelValue="(...v) => updateHandler(subkey, ...v)"
                        :disabled="props.disabled"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
// based on poliform
// added the query store, since all sub-components in logical operations have the same format definitions
// so the format is not passed as props, it comes from the store

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import { structuredClone } from "../../helper-functions.mjs";
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
function clone(p) {
    return structuredClone(toRaw(p));
}
import { useQueryStore } from "@/store/queryStore.mjs";
const store = useQueryStore();

// identifier and index are needed for flexiform array index identification.
const props = defineProps(["format", "modelValue", "disabled", "identifier", "index"]);
const emit = defineEmits(["update:modelValue"]);

const data = reactive(clone(props.modelValue || {}));

// validators
const valid = reactive({});
const isValid = () => {
    //for (const i in valid) if (valid[i] !== true) return i + ": " + valid[i];
    return true;
};

const updateHandler = (from, value, nexus = { valid: true, change: false }) => {
  	data[from] = value;
    const datum = clone(data);
    emit("update:modelValue", datum, { valid: isValid(), change: true });
};

onMounted(async () => {
});
</script>

<script>
export default {
    inheritAttrs: false,
    name: "vuetiform-queriesform",
};
</script>
