<template>
    <div class="pa-16" v-if="data.format.database_collection?.items?.length > 0">
        <!--vuetifind-dialog :format="data.format" :values="selection" @update="update" @clickEnter="add" label="##&en Add new ##&hu Hozzáadása ##" /-->
        <vuetifind-dialog icon="mdi-plus" :format="data.format" :modelValue="selection" @update:modelValue="add" label="##&en Add Eventhandler ##&hu Eseménykezelő Hozzáadása ##" />
        <selector-component :structure="data.structure" @leftClickElement="leftClickElement" @rightClickElement="rightClickElement" />
    </div>
    <v-container v-else>
        <v-alert
            v-if="data.format.database_collection"
            density="compact"
            type="warning"
            title="##&en No collection for eventhandlers ##&hu Nincs eseménykezelés kollekció ##"
            text="##&en In order to create one, you  need to create a collection with the eventhandlers pattern. ##&hu Eseménykezelők használatához létre kell hozni egy adatbázis kollekciót, ami az eseménykezelők pattern alapján dolgozik ##"
        ></v-alert>
    </v-container>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";

import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";
import VuetifindDialog from "@/vuetifind-components/VuetifindDialog.vue";

const router = useRouter();

const selection = reactive({});

const data = reactive({
    structure: {},
    format: {},
});

import validators from "../validators.mjs";
const variableRule = (input) => {
    if (!validators.validateVariable(input)) return "Invalid";
    return true;
};

const dialogValid = () => {
    if (!dialog.collection) return "Collection";
    if (!dialog.callname) return "Callname";
    if (!validators.validateVariable(dialog.collection)) return "Collection invalid";
    if (!validators.validateVariable(dialog.callname)) return "Callname invalid";
    return true;
};

const getDatabases = () => {
    return Object.keys(data.databases);
};

const getCollections = (db) => {
    if (!db) return [];
    if (!data.databases[db]) return [];
    return Object.keys(data.databases[db]);
};

const leftClickElement = (e) => {
    router.push("/eventhandler/" + e.database + "/" + e.collection + "/" + e._id);
};
const rightClickElement = (e) => {
    window.open("/eventhandler/" + e.database + "/" + e.collection + "/" + e._id, "_blank");
};

const add = (a) => {
  	Object.assign(selection, a);
    $socket.emit("eventhandlereditor:EventhandlerSelection:add", { selection }, ({ database, collection, _id }) => {
        if (_id) router.push("/eventhandler/" + database + "/" + collection + "/" + _id);
    });
};

onMounted(async () => {
    await $connection();

    $socket.on("update-eventhandlers", (o) => {
        Object.assign(data, o);
    });

    $socket.emit("eventhandlereditor:EventhandlerSelection:update", {}, (o) => {
        Object.assign(data, o);
    });
});

onUnmounted(async () => {
    $socket.removeListener("update-eventhandlers");
});
</script>
<script>
export default {
    path: "/eventhandlers",
    name: "##&en Eventhandlers ##&hu Eseménykezelők ##",
    icon: "mdi-ballot",
    root: "Home",
};
</script>
