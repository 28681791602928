<template>
    <div :class="props.classes || 'text-h7 pa-2 ml-2'">
        <v-combobox
            v-model="data.value"
            :label="props.label"
            :items="getItems()"
            @input="updateHandler()"
            @update:modelValue="updateHandler()"
            :prepend-icon="props.icon || 'mdi-currency-usd'"
            append-icon="mdi-code-parentheses-box"
            clearable
        />
        <v-chip @click="arithmetic()" class="ma-2"> $( Arithmetica ) </v-chip>
        <v-chip v-for="(chip, i) in getItems()" @click="drop(chip)" class="ma-1">
            {{ chip.value }}
        </v-chip>
    </div>
</template>
<script setup>
// Deprecated?

import { isProxy, toRaw } from "vue";
import { ref, reactive, nextTick, onMounted } from "vue";
import { structuredClone as clone } from "../../helper-functions.mjs";
import { getCurrentInstance } from "vue";

import { useEventhandlerStore } from "@/store/eventhandlerStore.mjs";
const store = useEventhandlerStore();

function isObject(i) {
    return Object.prototype.toString.call(i) === "[object Object]";
}

const props = defineProps(["label", "classes", "modelValue", "items", "icon", "mandatory", "filters"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ value: clone(props.modelValue || "") });

function drop(chip) {
    if (isObject(data.value)) data.value = data.value.value;
    if (!data.value) data.value = "";
    else data.value += " ";
    data.value += chip.value;
    updateHandler();
}

function arithmetic() {
    if (isObject(data.value)) data.value = data.value.value;
    if (!data.value) data.value = "";
    data.value = "$( " + data.value + " )";
    updateHandler();
}

const getItems = () => {
    const unfiltered = store.getContextkeys(props.bond.stack);
    if (!props.filters) return unfiltered;
    const filters = props.filters.split("|");
    const filtered = unfiltered.filter((e) => filters.includes(e._type));
    return filtered;
};

function isValid() {
    const items = getItems().map((e) => {
        if (isObject(e)) return e.value;
        return e;
    });

    if (props.mandatory) {
        if (data.value === undefined) return props.label + " - ##&en Mandatory field ##&hu kötelező mező ##";
        if (data.value === null) return props.label + " - ##&en Mandatory field, null value ##&hu kötelező mező, null érték ##";
        if (isObject(data.value)) return items.includes(data.value.value);
        else if (data.value.length < 2) return props.label + " - ##&en too short ##&hu túl rövid ##";
    }

    if (data.value === null) return true;

    let valueString = data.value;
    if (isObject(data.value)) valueString = data.value.value;

    const regex = /\$[\w.]+/g;
    const values = valueString.match(regex);

    if (!values) return true;
    for (const match of values) if (!items.includes(match)) return match + " ##&en does not match ##&hu nem található ##";
    return true;
}

async function updateHandler() {
    await nextTick();
    let datum = clone(data.value);
    // combobox can return strings or objects
    if (isObject(datum)) datum = clone(data.value.value);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", isValid());
}

onMounted(updateHandler);
</script>

<script>
export default {
    inheritAttrs: false,
    name: "eventhandler-contextkey-solvable-text-combobox",
};
</script>
