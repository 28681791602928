<template>
    <v-container>
        <pre class="ma-4">{{ getPreComment() }}</pre>
        <vue-codemirror v-model="data.value" @change="updateHandler" :extensions="extensions" :style="{ height: '400px' }" />
    </v-container>
</template>

<script setup>
import { ref, reactive, watch, nextTick, onMounted, toRaw } from "vue";
function clone(p) {
    return structuredClone(toRaw(p));
}

import { useEventhandlerStore } from "@/store/eventhandlerStore.mjs";
const store = useEventhandlerStore();

function getItems() {
    const unfiltered = store.getContextkeys(props.bond.stack);
    if (!props.filters) return unfiltered;
    const filters = props.filters.split("|");
    const filtered = unfiltered.filter((e) => filters.includes(e._type));
    return filtered;
}

function getPreComment() {
    return getItems()
        .map((e) => "// " + e._type + " " + e.value)
        .join("\n");
}

const props = defineProps(["bond", "modelValue", "identifier", "module", "channel"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

const data = reactive({ value: clone(props.modelValue || {}), valid: true, key: 0 });

const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

async function refresh() {
    Object.assign(data, { value: clone(props.modelValue || {}) });
    await nextTick();
    //if (refs.component) if (refs.component.refresh) await refs.component.refresh("EventhandlerJavascript");
}

defineExpose({ refresh });

async function updateHandler() {
    await nextTick();
    const datum = clone(data.value);
    emit("update", datum);
    emit("update:modelValue", datum);
    emit("update:valid", data.valid);
}

onMounted(async () => {});

/// codemirror setup
import {
    keymap,
    highlightSpecialChars,
    drawSelection,
    highlightActiveLine,
    dropCursor,
    rectangularSelection,
    crosshairCursor,
    lineNumbers,
    highlightActiveLineGutter,
} from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { defaultHighlightStyle, syntaxHighlighting, indentOnInput, bracketMatching, foldGutter, foldKeymap } from "@codemirror/language";
import { defaultKeymap, history, historyKeymap } from "@codemirror/commands";
import { searchKeymap, highlightSelectionMatches } from "@codemirror/search";
import { autocompletion, completionKeymap, closeBrackets, closeBracketsKeymap } from "@codemirror/autocomplete";
import { lintKeymap } from "@codemirror/lint";
const basicSetup = (() => [
    lineNumbers(),
    highlightActiveLineGutter(),
    highlightSpecialChars(),
    history(),
    //foldGutter(),
    drawSelection(),
    dropCursor(),
    EditorState.allowMultipleSelections.of(true),
    indentOnInput(),
    syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
    bracketMatching(),
    //closeBrackets(),
    autocompletion(),
    rectangularSelection(),
    crosshairCursor(),
    highlightActiveLine(),
    highlightSelectionMatches(),
    keymap.of([...closeBracketsKeymap, ...defaultKeymap, ...searchKeymap, ...historyKeymap, ...foldKeymap, ...completionKeymap, ...lintKeymap]),
])();

//-------------
const extensions = [keymap.of(defaultKeymap), basicSetup];

//import { linter, lintGutter } from "@codemirror/lint";
import { javascript } from "@codemirror/lang-javascript";
extensions.push(javascript());
//extensions.push(lintGutter());

//import { lintKeymap } from "@codemirror/lint";
//import { esLint } from "@codemirror/lang-javascript";
//import { tags as t } from "@lezer/highlight";

import { oneDark } from "@codemirror/theme-one-dark";
extensions.push(oneDark);
</script>

<script>
export default {
    inheritAttrs: false,
    name: "eventhandler-code-editor",
};
</script>
