<template>
    <div v-if="route.params.database" id="selector-content">
        <h2>{{ (data.databases[route.params.database] || {}).name }}</h2>
        {{ route.params.database }}
        <selector-component :structure="data.structure[route.params.database]" @leftClickElement="leftClickCollection" @rightClickElement="rightClickCollection" :key="route.params.database" />
    </div>
    <div v-else class="pa-10">
        <h2>##&en Databases ##&hu Adatbázisok ##</h2>
        <v-container fluid>
            <v-row>
                <selector-component :structure="Object.values(data.databases)" @leftClickElement="leftClickDatabase" @rightClickElement="rightClickDatabase" />
            </v-row>
        </v-container>
    </div>
</template>

<script setup>
import { onMounted, onUnmounted, reactive } from "vue";
import SelectorComponent from "@/vuetifind-components/VuetifindSelection.vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

const data = reactive({
    databases: {},
    structure: {},
});

const leftClickCollection = (e) => {
    data.c++;
    router.push("/database/" + e.database + "/" + e.collection);
};
const rightClickCollection = (e) => {
    window.open("/database/" + e.database + "/" + e.collection, "_blank");
};

const leftClickDatabase = (e) => {
    data.d++;
    router.push("/database/" + e.database);
};
const rightClickDatabase = (e) => {
    window.open("/database/" + e.database, "_blank");
};

function init() {
    //const database = route.params.database;
    $socket.emit("dataview:Database:init", {}, (o) => {
        Object.assign(data, o);
    });
}

function getDatabaseSelectionStructure() {
    return Object.keys(data.structure).map((name) => ({ name, icon: "mdi-database" }));
}

onMounted(async () => {
    await $connection();
    init();

    $socket.on("dataview:Database:update", (o) => {
        Object.assign(data, o);
    });
});
onUnmounted(async () => {
    $socket.removeListener("dataview:Database:update");
});
</script>
<script>
export default {
    path: "/database/:database?",
    name: "##&en Databases ##&hu Adatbázisok ##",
    icon: "mdi-database",
    sort: 100,
    root: "Home",
};
</script>
<style scoped>
#selector-content {
    padding: 24px;
    padding-bottom: 100px;
}
</style>
