<template>
    <QRCodeVue3
        v-if="data.accesscode"
        :dotsOptions="{
            type: 'square',
            color: '#000000',
        }"
        :cornersSquareOptions="{ type: 'square', color: '#000000' }"
        :cornersDotOptions="{ type: 'square', color: '#000000' }"
        :value="data.accesscode"
    />
    {{ data.accesscode }}
</template>

<script setup>
import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const data = reactive({
    accesscode: null,
});

onMounted(async () => {
    await $connection();

    $socket.emit("get-fox-passcode", ({ hostname, passport, passcode }) => {
        data.accesscode = "$F0X://" + "HOSTNAME=" + hostname + "|PASSPORT=" + passport + "|PASSCODE=" + passcode;
    });
});
</script>
<script>
import QRCodeVue3 from "qrcode-vue3";
</script>
<style scoped></style>
