<template>
    <v-container fluid style="padding: 0">
        <v-row no-gutters>
            <v-col cols="1" style="min-width: 120px; max-width: 120px">
                <v-chip
                    id="menu-activator"
                    prepend-icon="mdi-comment"
                    title="Comment"
                    variant="flat"
                    color="primary"
                    class="ma-2 px-5"
                    text="Comment"
                    @click="
                        add();
                        show = !show;
                    "
                />
            </v-col>

            <v-col cols="11" style="min-width: 200px; max-width: 600px">
                <v-slide-x-transition>
                    <v-card v-show="show" class="text-block">
                        <v-textarea
                            auto-grow
                            rows="1"
                            single-line
                            variant="solo-filled"
                            hide-details
                            v-if="show"
                            autofocus
                            append-inner-icon="mdi-send"
                            @click:appendInner="add()"
                            v-model="text"
                            @keyup.enter.exact.prevent="add()"
                            label="##&en Write a comment ... ##&hu Komment írása ... ##"
                        />
                    </v-card>
                </v-slide-x-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup>
import { onMounted, ref } from "vue";

const emit = defineEmits(["add"]);

const show = ref(false);
const text = ref(null);

const add = () => {
    if (!text.value) return;
    emit("add", text.value);
    /*$socket.emit("comments:add", { text }, (o) => {
      	text.value = null;
    });*/
    text.value = null;
    show.value = false;
};

onMounted(async () => {
    await $connection();
});
</script>
<style scoped>
.text-block {
    position: relative;
    top: -10px;
    left: 16px;
}
</style>
