<template>
    <ExecutionFeedback />
    <ExecutionHistory />
    <v-container fluid class="pa-16">
        <v-toolbar>
            <v-btn icon size="large" @click.native="emitDocument('save')" :disabled="data.valid !== true || data.readonly">
                <v-icon>mdi-floppy</v-icon>
            </v-btn>
            <v-btn icon size="large" @click.native="emitDocument('call')" :disabled="data.valid !== true || data.readonly">
                <v-icon>mdi-play</v-icon>
            </v-btn>
            <v-toolbar-title>
                <pre> {{ route.params.database }} / {{ route.params.collection }} / {{ data.eventhandler.name || data._id }} {{ getVersion(data.eventhandler._version) }}</pre>
            </v-toolbar-title>
            <v-btn vaicon size="large" @click.native="trashClick()" :color="data.removal ? 'red' : 'gray'">
                <v-icon>mdi-delete</v-icon>
            </v-btn>
        </v-toolbar>
        <v-container fluid>
            <DocumentVersions
                v-if="data.versions.length > 1"
                :bond="{ module: 'eventhandlereditor', page: 'eventhandler', database: route.params.database, collection: route.params.collection, id: route.params.id }"
                :database="route.params.database"
                :collection="route.params.collection"
                :id="route.params.id"
                :version="store.eventhandler._version"
                :versions="data.versions"
                :key="data.eventhandler._version"
            />
            <v-btn color="primary" variant="elevated" class="ma-1" min-width="200" @click.native="emitDocument('executionhistory')">
                <p style="text-transform: none"><v-icon class="pr-3">mdi-playlist-check</v-icon>##&en Executions ##&hu Futtatások ##</p>
            </v-btn>
        </v-container>
        <vuetiform-component
            :format="{ is: 'vuetiform-poliform', format: data.format, label: 'Eventhandler' }"
            :modelValue="data.eventhandler"
            @update:modelValue="updateHandler"
            :bond="bond"
            :ref="getRef('component')"
            :key="route.params.id"
        />
    </v-container>
    <div>
        <v-btn flat icon="mdi-triangle-small-down" size="small" @click.native="data.debug = !data.debug" style="background-color: transparent" />
        <pre v-if="data.debug">{{ data }}</pre>
    </div>
</template>

<script setup>
// readonly: route.params.version !== data.eventhandler.version

/*
    <div v-if="data.debug">
        EventhandlerEditorDocument
        <pre>{{ data.eventhandler }}</pre>
        store
        <pre>{{ store.eventhandler }}</pre>
        FORMAT
        <pre>{{ data.format }}</pre>
    </div>
*/

import { ref, onMounted, onUnmounted, reactive, computed, watch, nextTick, toRaw } from "vue";
import { useRouter, useRoute } from "vue-router";

//import VuetiformPoliform from "@/vuetiform/VuetiformPoliform.vue";
import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
import ExecutionFeedback from "@/eventhandlereditor-components/ExecutionFeedback.vue";
import ExecutionHistory from "@/eventhandlereditor-components/ExecutionHistory.vue";
import DocumentVersions from "@/database-components/DocumentVersions.vue";

const router = useRouter();
const route = useRoute();

// we use a store to be able to calculate context key validity
import { useEventhandlerStore } from "@/store/eventhandlerStore.mjs";
const store = useEventhandlerStore();

const data = reactive({
    _id: route.params.id,
    eventhandler: {},
    key: 0,
    readonly: true,
    versions: [],
    format: {},
  	valid: true
});

const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => {
        refs[key] = el;
    };
}

function getVersion(id) {
    if (!id) return "-";
    const vid = id.substring(18);
    const n = parseInt(vid.slice(-6), 16);
    if (!isNaN(n)) return "v" + n;
    return vid;
}

async function refresh() {
    await nextTick();
    if (refs.component) if (refs.component.refresh) refs.component.refresh();
}

const bond = {
    module: "eventhandlereditor",
    page: "EventhandlerEditorDocument",
    database: route.params.database,
    collection: route.params.collection,
    id: route.params.id,
    stack: [],
};

function trashClick() {
    if (!data.removal) {
        data.removal = true;
        setTimeout(() => {
            data.removal = false;
        }, 2000);
        return;
    }
    emitDocument("remove");
}

async function emitDocument(act, options = {}) {
    await nextTick();
    $socket.emit(
        "eventhandlereditor:EventhandlerEditorDocument:" + act,
        {
            id: route.params.id,
            eventhandler: data.eventhandler,
        },
        async (o) => {
            if (o.uri) return router.push(o.uri);
            store.assign(o);
            Object.assign(data, o);
            if (options.refresh) await refresh();
            //if (o.router) router.push(o.router);
            router.push({ params: { version: data.eventhandler._version } });
        },
    );
}

function updateHandler(datum, nexus = { valid: true, refresh: false }) {
  	data.valid = nexus.valid;
    data.eventhandler = datum;
    //return emitDocument("update", { refresh: true });
}

function validHandler() {}

function init(by) {
    //Ł(by);
    if (route.params.id)
      	Ł("init", by);
        $socket.emit(
            "eventhandlereditor:EventhandlerEditorDocument:init",
            {
                id: route.params.id,
                version: route.params.version,
                execid: route.params.execid,
            },
            (o) => {
                if (o.uri) return router.push(o.uri);
                if (o.router) router.push(o.router);
                store.assign(o);
                Object.assign(data, o);
                router.push({ params: { version: data.eventhandler._version } });
              	refresh();
            },
        );
}

onMounted(async () => {
    await $connection();
    init("onMounted");
});

onUnmounted(async () => {});

watch(
    () => route.params,
    (newParams, oldParams) => {
        //if (newParams.database !== oldParams.database) return init('database');
        //if (newParams.collection !== oldParams.collection) return init('collection');
        if (newParams.id !== oldParams.id) return init("id");
        if (newParams.version !== data.eventhandler._version) return init("version");
        //if (newParams.execid !== oldParams.execid) return init('execid');
    },
);
</script>
<script>
export default {
    path: "/eventhandler/:database/:collection/:id/:version?/:execid?",
    name: "eventhandler-editor-document",
    icon: "mdi-cog-box",
    sort: 110,
    root: "Home",
};
</script>
