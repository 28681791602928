<template>
    <v-container>
        <v-row justify="space-around">
            <v-card width="500">
                <v-card-title>
                    <div class="text-h5 pa-1 mb-4">Vuetiform Component</div>
                </v-card-title>

                <v-card-text>
                    <vuetiform-component
                        :format="{ is: 'vuetiform-poliform', format: data.format }"
                        :modelValue="data.form"
                        @update:modelValue="updateHandler"
                        :ref="getRef('component')"
                    />
                </v-card-text>
                <v-card-subtitle> </v-card-subtitle>
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="">button</v-btn>
                </v-card-actions>
            </v-card>
        </v-row>
    </v-container>
    data {{ data }} valid {{ valid }}
</template>

<script setup>
import { ref, reactive, onMounted } from "vue";

import VuetiformComponent from "@/vuetiform/VuetiformComponent.vue";
const data = reactive({
    format: {
        selector: {
            is: "v-select",
            label: "Select",
            items: ["One", "two"],
        },
    },
  	form: {}
});

// :ref="getRef('component')"
const refs = reactive({
    component: null,
});

function getRef(key) {
    return (el) => (refs[key] = el);
}

const valid = reactive({
    email: true,
});

const format1 = {
    is: "v-text-field",
    label: "E-mail",
    validators: "validateEmail",
};
const format2 = {
    is: "v-select",
    label: "Select",
    items: ["One", "two"],
};

function updateHandler(datum, nexus) {}
</script>

<script>
export default {
    path: "/hello-vuetiform-component",
    name: "hello-vuetiform-component",
    icon: "mdi-medical-bag",
    sort: 9000,
};
</script>
