<template>
    <v-toolbar density="compact">
        <v-btn @click="startStopClick()" size="x-large">
            <v-icon dark> {{ data.playing ? "mdi-stop-circle" : "mdi-play-circle" }} </v-icon>
        </v-btn>
        <pre> {{ data.headline }} {{ data.selected[0] }}</pre>
        <v-spacer />
        <v-btn @click="zoomSreen(-250)" :disabled="data.width <= 500" size="x-large">
            <v-icon dark> mdi-magnify-plus-cursor </v-icon>
        </v-btn>
        <v-btn @click="zoomSreen(250)" :disabled="data.width >= 5000" size="x-large">
            <v-icon dark> mdi-magnify-minus-cursor </v-icon>
        </v-btn>
        <v-btn @click="zoomTime(1.28)" :disabled="data.timespan < 10" size="x-large">
            <v-icon dark> mdi-magnify-plus </v-icon>
        </v-btn>
        <v-btn @click="zoomTime(0.8)" :disabled="data.timespan > 200000000" size="x-large">
            <v-icon dark> mdi-magnify-minus </v-icon>
        </v-btn>
        <v-btn @click="zoomHeight(1.25)" size="x-large">
            <v-icon dark> mdi-magnify-plus-outline </v-icon>
        </v-btn>
        <v-btn @click="zoomHeight(0.8)" :disabled="data.height < 40" size="x-large">
            <v-icon dark> mdi-magnify-minus-outline </v-icon>
        </v-btn>
        <v-spacer />
        <v-btn v-if="data.editable" @click="toggleDragging()" size="x-large">
            <v-icon dark> {{ data.dragging ? "mdi-arrow-left-right" : "mdi-arrow-horizontal-lock" }} </v-icon>
        </v-btn>
        <v-btn v-if="data.editable" class="mx-2" fab dark color="primary" @click="toggleSelecting()" size="x-large">
            <v-icon dark>{{ data.selecting ? "mdi-select" : "mdi-pencil-box" }} </v-icon>
        </v-btn>

        <v-btn v-if="data.editable" class="mx-2" fab dark color="primary" @click="openDocument(data.selected[0])" :disabled="data.selected.length !== 1" size="x-large">
            <v-icon dark> mdi-pencil </v-icon>
        </v-btn>

        <v-btn v-if="data.editable" class="mx-2" fab dark color="primary" @click="openDocument()" size="x-large">
            <v-icon dark> mdi-plus </v-icon>
        </v-btn>
        <v-btn v-if="data.editable" class="mx-2" fab dark color="primary" @click="removeDocument()" size="x-large">
            <v-icon dark> mdi-delete-circle </v-icon>
        </v-btn>

        <v-btn class="mx-2" fab dark color="primary" @click="editor()" size="x-large">
            <v-icon dark> mdi-application-edit </v-icon>
        </v-btn>
    </v-toolbar>
    <v-card v-resize="onResize" class="pa-4 bg-grey-lighten">
        <v-card height="50" class="scroll gray">
            <div id="timehead-d3" />
        </v-card>
        <v-card :height="resizedHeight" class="scroll gray">
            <div id="timeline-d3" />
        </v-card>
    </v-card>
    <v-dialog v-model="data.dialog" fullscreen :scrim="false" transition="dialog-bottom-transition"
        ><v-card>
            <v-toolbar dark color="primary">
                <v-spacer></v-spacer>
                <v-btn
                    icon
                    dark
                    @click="
                        update();
                        data.dialog = false;
                    "
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <document-view :database="data.query.database" :collection="data.query.collection" :id="data.id" :redirecting="false" />
        </v-card>
    </v-dialog>
</template>

<script setup>
import { ref, onMounted, onUnmounted, reactive, computed, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import timeline_d3 from "@/timeline-d3/timeline.d3.mjs";
import DocumentView from "@/database-components/DocumentView.vue";

const router = useRouter();
const route = useRoute();

const key = "timeline-" + route.params.database + "-" + route.params.id;

import { useTimelineStore } from "@/store/timelineStore.mjs";
const store = useTimelineStore();

let render = () => {};
let startStopClick = () => {};

const data = reactive({
    database: route.params.database,
    _id: route.params.id,
    width: 2000,
    height: 100,
    playing: false,
    timespan: null,
    startdate: new Date(),
    timefrom: Math.floor(new Date().getTime() / 1000),
    //editable_by_dragging: true,
    //editable_by_click: true,
    selected: [],
    dragging: false,
    editable: true,
    selecting: false,
    headline: "",
    key: 0,
    dialog: false,
    query: {
        database: null,
        collection: null,
    },
    id: null,
});

function zoomSreen(p) {
    const np = data.width + p;
    if (np > 5000 || np < 500) return;
    data.width += p;
    render();
}
function zoomTime(p) {
    const i = data.timespan / p;
    if (i < 100 || i > 315576000) return;
    const center = Math.round(data.timefrom + data.timespan / 2);
    data.timespan = Math.round(data.timespan / p);
    data.timefrom = Math.round(center - data.timespan / 2);
    render();
}
function zoomHeight(p) {
    const h = Math.round(data.height * p);
    if (h < 50 || h > 1000) return;
    data.height = Math.round(data.height * p);
    render();
}

function toggleDragging() {
    data.dragging = !data.dragging;
    render();
}

function toggleSelecting() {
    data.selecting = !data.selecting;
    render();
}

function timlineDatagramUpdate(datagram, updatekey) {
    const timeline = {};
    timeline.database = route.params.database;
    timeline.collection = "timeline";
    timeline.id = route.params.id;
    $socket.emit("timeline-datagram-update", { datagram, updatekey, timeline }, () => {});
}
function timelineDatagramClick(datagram) {
    const id = datagram.id;
    openDocument(id);
    //const timeline = {};
    //timeline.database = route.params.database;
    //timeline.collection = "timeline";
    //timeline.id = route.params.id;
    //$socket.emit("timeline-datagram-click", { datagram, timeline }, () => {});
}
const resizedHeight = ref(0);
const onResize = () => {
    resizedHeight.value = window.innerHeight - 200;
};

const editor = () => {
    router.push("/timeline-editor/" + route.params.database + "/timeline/" + route.params.id);
};

const openDocument = (id) => {
    const database = store.data[key].query.database;
    const collection = store.data[key].query.collection;
    if (id) {
        Ł(id);
        data.id = id;
        data.dialog = true;
        return;
    }
    // get an id
    $socket.emit("dataview:Document:open", { database, collection, id }, ({ id }) => {
        data.id = id;
        data.dialog = true;
    });
};

const removeDocument = () => {
    const database = store.data[key].query.database;
    const collection = store.data[key].query.collection;
    const document = data.selected;
    $socket.emit("dataview:RemoveDocument", { database, collection, document }, () => {
        update();
    });
};

const update = () => {
    $socket.emit("update-timeline-document", {
        database: route.params.database,
        _id: route.params.id,
    });
};

let interval;
onMounted(async () => {
    await $connection();
    const { handler } = store.init({
        database: route.params.database,
        _id: route.params.id,
    });

    // init
    handler.push(() => {
        if (data.timespan !== null) return;

        const definition = store.data[key].definition;

        data.timespan = definition.timespan;
        data.startdate = new Date(new Date().getTime - (data.timespan / 2) * 1000);
        data.timefrom = Math.floor(data.startdate.getTime() / 1000);
        data.editable = definition.editable;
        //data.editable_by_click = definition.editable;
        data.dragging = definition.dragging;
        data.query = store.data[key].query;

        const t3 = timeline_d3(data, store.data[key], { timlineDatagramUpdate, timelineDatagramClick });
        render = t3.render;
        handler.push(render);

        interval = setInterval(t3.timer, 990);

        startStopClick = () => {
            data.playing = !data.playing;
            if (!data.playing) return;
            data.startdate = new Date();
            t3.timer();
        };

        data.headline = store.data[key].query.database + "." + store.data[key].query.collection + " " + definition.name;
    });
});

onUnmounted(async () => {
    clearInterval(interval);
    render = () => {};
    startStopClick = () => {};
});
</script>
<script>
import * as d3 from "d3";
export default {
    path: "/gui/:database/timeline/:id",
    name: "timeline-document",
    icon: "mdi-monitor-dashboard",
    sort: 110,
    root: "Home",
};
</script>
<style scoped>
.scroll {
    overflow-y: scroll;
}
.gray {
    background-color: lightgray;
}
</style>
