export default function test(str) {
    //if (!str) return false;
    if (str === "") return false;
    // regexp based check
    if (!/^\$/.test(str)) return "##&en Must start with a $ sign ##&hu $ karakterrel kell kezdődnie ##";
    if (!/^.{4,}$/.test(str)) return "##&en Too short ##&hu Túl rövid ##";
    if (!/^\$[a-z_]/.test(str)) return "##&en may contain only a-z and underscore ##&hu Kisbetűs a-z és alsócsík karaktereket tartalmazhat ##";
    if (!/^\$[a-z_]{3,64}$/.test(str)) return "##&en Invalid variable name ##&hu Nem érvényes változónév ##";
    return true;
}

test.title = "##&en Dollar sigill variable, eg ##&hu Dolláral kezdődő változónév, pl ## $alpha";