<template>
    <collection-view :database="route.params.database" :collection="route.params.collection" :key="route.params.database + '.' + route.params.collection"/>
</template>

<script setup>
import CollectionView from "@/database-components/CollectionView.vue";

import { ref, onMounted, reactive, computed, watch } from "vue";

import { useRouter, useRoute } from "vue-router";
const router = useRouter();
const route = useRoute();

</script>

<script>
export default {
    path: "/database/:database/:collection",
    name: "database-collection",
    icon: "mdi-database",
    sort: 100,
    root: "Home",
};
</script>

<style scoped></style>
