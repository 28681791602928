/* boilerplate globalThis */

//https://socket.io/docs/v4/client-options/

import { io } from "socket.io-client";

export default function () {
    const options = ß.SOCKETIO_OPTIONS || {};

    const connect = "https://" + ß.HOSTNAME + ":" + ß.SERVER_PORT;
    //console.log("[socket.io] " + connect, options);

    options.withCredentials = true;

    const socket = io(connect, options);
    //console.log(ß.SOCKETIO_OPTIONS);
    //const socket = io(options);

    globalThis.$socket = socket;

    globalThis.$connection = async () => {
        while (!socket.connected) await new Promise((resolve) => setTimeout(resolve, 100));
    };

    socket.on("disconnect", function (reason) {
        const transport = socket.io.engine.transport.name;
        console.log(transport + " disconnected, " + reason);
    });
}

/*
Usage in script setup

$.onMounted(async () => {
    console.log("onMounted", $socket.connected);
	await $connection();
	// use the $socket
}
*/
