import { createRouter, createWebHistory } from "vue-router";
import components from "../vue-components.mjs";

function sortByMeta(a, b) {
    if (a.meta.sort > b.meta.sort) return 1;
    if (a.meta.sort < b.meta.sort) return -1;
    return 0;
}

function getComponentRoute(filename) {
    const component = components[filename];
    if (typeof component.path === "string" || typeof component.link === "string") {
        const route = { meta: {} };
        route.component = component;
        route.path = component.path || "/" + filename.toLowerCase();
        route.name = component.name || filename;
        route.meta.name = component.name || filename;
        route.meta.link = component.link || component.path || "/" + filename.toLowerCase();
        route.meta.icon = component.icon || "mdi-navigation-variant-outline";
        route.meta.sort = component.sort || 1000;
        route.meta.filename = filename;
        route.meta.root = component.root || "root";
        route.meta.section = component.section || null;
        return route;
    }
    return null;
}

export default function ({ app }) {
    const allroutes = new Map();
    const parents = new Set();

    Object.keys(components).forEach((filename) => {
        const route = getComponentRoute(filename);
        if (!route) return;
        allroutes.set(filename, route);
        parents.add(route.meta.root);
    });

    function getChildren(from) {
        const children = new Map();

        allroutes.forEach((route, filename) => {
            if (route.meta.root !== from) return;
            // children are identified by filename
            const arr = getChildren(filename);
            if (arr.length > 0) route.children = arr;
            children.set(filename, route);
        });

        return Array.from(children.values()).sort(sortByMeta);
    }

    const routes = getChildren("root");

    /*function displayRoutes(routes, parentPath = "") {
        return routes
            .map((route) => {
                // Construct the full path by concatenating the parent path and the current path
                const fullPath = `${parentPath}/${route.path}`.replace("//", "/");

                // Prepare the display string for the current route
                const displayString = `${fullPath} ${route.meta?.filename || ""}`;

                // If there are child routes, recursively get their display strings
                if (route.children && route.children.length > 0) {
                    const childRoutesDisplay = displayRoutes(route.children, fullPath);
                    return `${displayString} | ${childRoutesDisplay}`;
                }

                // Return the display string for the current route
                return displayString;
            })
            .join(" | ");
    }

    // console.log("[vue/router] " + displayRoutes(routes));
  	*/
  
    const router = createRouter({
        history: createWebHistory(),
        routes,
    });

    /*router.beforeEach((to, from, next) => {
        console.log("Navigating to:", to.name);
        next();
    });*/

    app.use(router);
}
