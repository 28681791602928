import { timespanSections } from "@/beacon-components/timelib.js";

export default function background(bkg, { timefrom_ms = 0, timespan_ms = 600000, WIDTH = 2000, HEIGHT = 1000 }) {

    const sections_data = timespanSections(timefrom_ms, timespan_ms, WIDTH, HEIGHT);
    bkg.selectAll("g").remove();
    const selections = bkg.selectAll("g").data(sections_data); //, (d) => d.t + "@" + timespan_ms);

    // the remover section
    selections.exit().remove();

    // the update section
    selections.attr("transform", (d) => "translate(" + d.x + ",0)");

    // the create section
    const timesections = selections
        .enter()
        .append("g")
        //.attr("id", (d) => d.t + "@" + timespan_ms)
        .attr("transform", (d) => "translate(" + d.x + ",0)");

    // create the text field
    timesections
        .append("text")
        .attr("y", 20)
        .text((d) => d.label)
        .attr("class", "title-text");

    timesections
        .append("rect")
        .attr("y", 40)
        .attr("x", 0)
        .attr("fill", (d) => {
            if (d.now) return "#c1c1c1";
            if (d.even === true) return "#d1d1d1";
            if (d.even === false) return "#d6d6d6";
            return "#eee";
        })
        .attr("width", (d) => d.width)
        .attr("height", (d) => HEIGHT);
        
}
