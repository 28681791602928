// VuetiformComponent.vue 46

export default async function functionalFormat({ format, value }) {
  	const reformat = {};
    for (const key in format) {
      	reformat[key] = format[key];
        if (key.includes(":") && typeof format[key] === "function") {
            const target = key.split(":").pop();
            const type = key.split(":")[0];
            const result = await format[key].call(this, value);

            if (Object.prototype.toString.call(result) === "[object " + type + "]") Object.assign(reformat, {[target]: result });
            else {
                console.error("%cError in " + key + ", return type is " + Object.prototype.toString.call(result), "color: red;");
                console.log(format[key]);
            }
        }  
    } 
  
  	return reformat;
}
