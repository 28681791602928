<template>
    <div class="comment-section">
        <div style="display: flex">
            <likes-chip v-model="representation.likes" @update="update('likes', pointer)" />
            <comment-chip @add="add" />
        </div>
        <v-sheet :width="1000 - pointer.length * 20" class="pl-8" v-for="(comment, index) in representation.comments">
            <div class="comment-body pa-5">
                <h5>{{ users[comment.user] }}</h5>
                <p>{{ comment.text }}</p>
            </div>
            <comment-section :bond="bond" v-model="representation.comments[index]" :pointer="pointer + '.' + index" @update="update()" />
        </v-sheet>
    </div>
</template>

<script setup>
const props = defineProps(["bond", "is", "modelValue", "class", "pointer"]);
const emit = defineEmits(["update:modelValue", "update:valid", "update"]);

import { ref, reactive, watch, nextTick, toRaw, onMounted } from "vue";
import { structuredClone } from "../../helper-functions.mjs";
import Commentsection from "@/vuetiform-components/CommentSection.vue";
import LikesChip from "@/vuetiform-components/LikesChip.vue";
import CommentChip from "@/vuetiform-components/CommentChip.vue";

import { useSocketdataStore } from "@/store/socketdataStore.mjs";
const socket = useSocketdataStore();

const users = reactive({});
let user = null;

const pointer = props.pointer || "@";
function clone(p) {
    return structuredClone(toRaw(p));
}

const add = (text) => {
    representation.comments.push({
        user,
        text,
        likes: [],
        comments: [],
    });
    update("comments", pointer, text);
};

const representation = reactive(
    clone(
        props.modelValue || {
            likes: [],
            comments: [],
        }
    )
);

const update = (channel, key, value) => {
    const modelValue = representation;
    emit("update", modelValue);
    emit("update:modelValue", modelValue);
    emit("update:valid", true);
    if (!key) return;
    if (!value) value = user;

    $socket.emit("comments:" + channel + ":update", { bond: props.bond, key, value }, (o) => {});
};

onMounted(async () => {
    await $connection();
    await socket.initialization;
    user = socket.data._id;

    const ids = [...representation.likes, ...representation.comments.map((e) => e.user)];
    $socket.emit("comments:common:getUserNames", { ids }, (o) => {
        Object.assign(users, o);
    });
});
</script>
<style scoped>
.comment-body {
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 10px;

    white-space: pre-wrap;
}
.comment-section {
    position: relative;
    top: -20px;
    left: 10px;
}
</style>
