// this maybe shown in the browser as source, when executing code
import { toRaw } from "vue";

export default function getFunction(code = ["return null;"]) {
    const that = this || {};
    const _fn = new Function(...code);
    const calls = {
        count: 0,
    };
    const executeFunction = async function executeFunction(...arg) {
        const context = { ...that, ...this };
        for (const key in context) if (typeof context[key] === "function") context[key.toLowerCase()] = await context[key]();
        const fn = _fn.bind(context);
        calls.count++;
        if (calls.count === 100) console.error("%cOver 100 calls. Suspicious.", "color: red;", code);
        if (calls.count > 1000) {
            console.error("%cOver 1000 calls.", "color: red;");
            console.log("getFunction context:", toRaw(that), "executeFunction context", toRaw(this), "argument-value:", toRaw(...arg), "function:", fn, "code:", toRaw(code));
            return "[OVERFLOW]";
        }
        try {
            const result = await fn(...arg);
            return result;
        } catch (err) {
            console.error("%cError in frontend-function", "color: red;");
            console.log("error:", err, "getFunction context:", toRaw(that), "executeFunction context", toRaw(this), "argument-value:", toRaw(...arg), "code:", toRaw(code));
            return "[ERROR]";
        }
    };

    executeFunction.code = code;
    executeFunction.calls = calls;

    return executeFunction;
}

/*

Context:

    this.document by this.Document() - the complete document
    this.format by this.Format() - the document format
    this.datafield by this.Datafield() - the datafield format

Argument: value

Caller
    VuetiformComponent - updateFunctionalvalues
    CollectionView - getTextValue
 
Compiler (format = formatFunction.call({..}, format))
    CollectionView
    DocumentView
    VuetiformServerform ?
    server-side ?

return:
	getComponentValue    
    getTextValue -> Human readble text

    setFieldValue - handled by the change event
    	@database-modules/database/database_collection/change.mjs
    	@database-modules/database/database-change.mjs
 
*/
