<template>
    <v-container>
      	<pre>{{ content }}</pre>
    </v-container>
</template>
<script setup>
import { onMounted, ref } from "vue";

const content = ref("##&en Welcome Boilerplate framework ##&hu Üdvözlet a boilerplate keretrendszerben ##");

onMounted(async () => {
    try {
        const response = await fetch("/changelog.txt");
        if (response.ok) {          	
            content.value = await response.text();
        } else {
            content.value = "##&en Error ##&hu Hiba ##";
            console.error("Failed to fetch the manual:", response.status);
        }
    } catch (error) {
        content.value = "##&en Error ##&hu Hiba ##";
        console.error("Error fetching the manual:", error);
    }
});

</script>
<script>
export default {
    path: "/",
    name: "project-home-page",
    icon: "mdi-home",
    sort: 10,
    root: "Home",
};
</script>

<style scoped>
.markdown-body {
    box-sizing: border-box;
    min-width: 200px;
    max-width: 2500px;
    margin: 0 auto;
    padding: 45px;
}
@media (max-width: 767px) {
    .markdown-body {
        padding: 15px;
    }
}
</style>
